import { parseHoldSpaceParams } from "../utils";
import { Container, AuxContainer } from "../elements";
import { BookingCard } from "../components";
import { db } from "../jsongo";
import { BookingDO, TourYearDO, tourEnds, yearFromDate } from "data-model";
import { SVG, ExternalLink } from "react-components";
import { FC } from "react";
import { useSearchParams, Navigate } from "react-router-dom";

const SpaceUnavailable: FC = () => {
  const [searchParams] = useSearchParams();
  const holdSpaceReq = parseHoldSpaceParams(searchParams);

  if (!holdSpaceReq) {
    return <Navigate to="/bookings" replace />;
  }

  const { tour, roomGuests, date } = holdSpaceReq;

  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: yearFromDate(date),
  }) as TourYearDO;

  const bookingDraft = {
    tour,
    roomGuests,
    departedAt: date,
    departure: {
      departedAt: date,
      concludedAt: tourEnds(date, tourYear),
    },
  } as unknown as BookingDO;

  return (
    <>
      <Container>
        <article className="is-flex margin-bottom-2">
          <SVG
            height={26}
            path="site/icon/alert-red"
            alt="Alert Red"
            className="padding-right-2"
          />
          <span className="is-flex-1">
            <h1 className="has-text-red margin-bottom-0">Unavailable</h1>
            <p className="is-marginless">
              Sorry, remaining space for your request was just held by another
              account.
            </p>
          </span>
        </article>

        <BookingCard booking={bookingDraft} hideNumber />
      </Container>
      <AuxContainer>
        <ExternalLink
          className="button is-yellow is-fullwidth"
          href={`${process.env.WWW_ORIGIN}/tour/${tour}`}
        >
          Back to Check Availability
        </ExternalLink>
      </AuxContainer>
    </>
  );
};

export { SpaceUnavailable };
