import { checkPwdToken, resetPassword } from "../api";
import { Password } from "../form";
import { AuxContainer, Container } from "../elements";
import { ErrorMessage, Input, useErrors } from "react-components";
import { FormEvent, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token && id) {
      catchErrors(
        async () => {
          try {
            const { email } = await checkPwdToken({ token, id });
            setEmail(email);
          } catch (e) {
            if (e instanceof Response && e.status === 401) {
              return navigate("/password/invalid", { replace: true });
            }
            throw e; // we do want to show any other error (ex: 500)
          }
        },
        () => setIsLoading(false)
      );
    }
  }, []);

  if (!token || !id) {
    return <Navigate to="/login" replace />;
  }

  const handlePasswordReset = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    catchErrors(
      async () => {
        setIsLoading(true);

        await resetPassword({ token, id: +id, password });

        navigate("/login", { replace: true });
      },
      () => setIsLoading(false)
    );
  };

  return (
    <form className="is-flex-column is-flex-1" onSubmit={handlePasswordReset}>
      <Container>
        <h1 className="is-marginless">Confirm New Password</h1>
        <hr className="divider is-red" />

        <label htmlFor="email" className="is-flex margin-top-3 margin-bottom-1">
          <strong>Email for this Account</strong>
        </label>
        <Input
          type="email"
          id="email"
          value={email}
          readOnly
          className="has-text-dark-gray"
        />

        <label
          htmlFor="password"
          className="is-flex margin-top-4 margin-bottom-1"
        >
          <strong>New Password for this Account</strong>
        </label>
        <Password
          id="password"
          label="New Password"
          parentClassName="margin-bottom-1"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          autoComplete="new-password"
        />
        <Password
          label="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          invalid={Boolean(
            password && confirmPassword && password !== confirmPassword
          )}
          autoComplete="new-password"
        />

        <ErrorMessage errors={errors} className="margin-top-3" />
      </Container>
      <AuxContainer className="is-flex">
        <Link className="button is-dark-gray margin-right-1" to="/login">
          Cancel
        </Link>
        <button
          type="submit"
          className="button is-yellow is-flex-1"
          disabled={
            !password ||
            !confirmPassword ||
            password !== confirmPassword ||
            isLoading
          }
        >
          Save and Continue
        </button>
      </AuxContainer>
    </form>
  );
};

export { ResetPassword };
