import { AccountDO } from "data-model";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

type ContextValue = [
  AccountDO | undefined,
  Dispatch<SetStateAction<AccountDO | undefined>>
];

const defaltValue: ContextValue = [
  undefined,
  () => {
    throw new Error("AccountContext ancestor not found in the tree");
  },
];

export const AccountContext = createContext(defaltValue);

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useState<AccountDO | undefined>();

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export const ACCOUNT_KEY = "account";
