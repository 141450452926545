import { emailReconfirmation } from "../api";
import { Container } from "../elements";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";

const EmailReconfirmation: FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const signature = searchParams.get("signature");

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(!id || !signature);

  const handleSend = async () => {
    if (!id || !signature) return;
    setIsLoading(true);

    try {
      const { email } = await emailReconfirmation(+id, { signature });
      setEmail(email);
      setHasError(false);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <h1 className="is-marginless">Confirmation PDF Email</h1>
      <hr className="divider is-red" />

      {hasError ? (
        <>
          <p>
            Sorry, this link is invalid; your PDF could not be emailed. Please
            retry clicking the link that was sent to you by email.
          </p>

          <p>If you are having trouble, please call Caravan Client Services.</p>
        </>
      ) : email ? (
        <p>
          Success! Your Confirmation PDF has been emailed to{" "}
          <strong>{email}</strong>. To request another confirmation, please
          click the link that was sent to you by email.
        </p>
      ) : (
        <>
          <p>
            To have your Confirmation PDF emailed to you, please click the link
            below.
          </p>

          <button
            className="button is-yellow margin-top-2 is-fullwidth"
            onClick={handleSend}
            disabled={isLoading}
          >
            Send a Confirmation PDF
          </button>
        </>
      )}
    </Container>
  );
};

export { EmailReconfirmation };
