import { ExistingBooking, ExistingBookingProps } from "../utils";
import { Container } from "../elements";
import { BookingCardContent } from "../components";
import { inviteAvailability } from "../api";
import { toursById } from "../jsongo";
import { sleep, AVAILABILITY_DELAY_MS, pluralize } from "data-model";
import { SVG } from "react-components";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const context = "invite-friends";

const InviteFriends: FC = () => <ExistingBooking component={SendInvite} />;

const SendInvite: FC<ExistingBookingProps> = ({ booking, account }) => {
  const navigate = useNavigate();

  const { number, tour: tourId } = booking;
  const tour = toursById[tourId];

  const [isLoading, setIsLoading] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);
  const [capacity, setCapacity] = useState<number>();

  useEffect(() => {
    (async () => {
      const promise = inviteAvailability({ number });

      await sleep(AVAILABILITY_DELAY_MS);
      const { available, capacity } = await promise;

      setIsAvailable(available);
      setCapacity(capacity);
      setIsLoading(false);
    })();
  }, []);

  const handleInvite = () => {
    const url = `${process.env.WWW_ORIGIN}/tour/${tour._id}?b=${number}&i=${account.firstName}`;

    if (navigator.share) {
      navigator.share({ url, title: tour.name });
    } else {
      location.href = `mailto:?subject=${encodeURIComponent(
        tour.name
      )}&body=${encodeURIComponent(url)}`;
    }
  };

  return (
    <Container>
      <h1 className="is-marginless" data-cy={`${context}-heading`}>
        Invite Friends
      </h1>
      <hr className="divider is-red" />

      <p className="margin-top-2 margin-bottom-3">
        Tours may have more than one daily departure.{" "}
        <strong>Send Invite Link</strong> to be certain friends can easily make
        their own booking and join you on your tour departure.
      </p>

      <div className="has-background-light-gray has-border-gray is-rounded-small is-line-height-medium">
        <div className="padding-3 is-flex">
          <SVG
            height={23}
            path={
              isLoading
                ? "site/icon/spinner"
                : isAvailable
                ? "site/icon/checkmark-circled-green"
                : "site/icon/alert-red"
            }
            alt={
              isLoading
                ? "Spinner"
                : isAvailable
                ? "Green Checkmark"
                : "Red Exclamation"
            }
          />

          <div className="margin-left-2">
            <h2
              className={`is-size-1 is-marginless is-line-height-medium ${
                isLoading ? "" : isAvailable ? "has-text-green" : "has-text-red"
              }`}
            >
              {isLoading
                ? "Checking Availability..."
                : isAvailable
                ? "Available!"
                : "Unavailable"}
            </h2>
            {!isLoading && (
              <p
                className={`is-marginless is-line-height-normal ${
                  isAvailable ? "has-text-green" : "has-text-red"
                }`}
              >
                <strong>
                  {isAvailable
                    ? `${
                        !capacity
                          ? "6 or more spots"
                          : `${capacity} spot${pluralize(capacity)}`
                      } available as of now for you to invite friends on your departure.`
                    : "Sorry, no spots available for you to invite friends on your departure."}
                </strong>
              </p>
            )}

            <div className="margin-y-3">
              <BookingCardContent
                booking={booking}
                numberIsAdjacent
                hideInvite
                hideGuests
                hideStatus
              />
            </div>

            <p className="is-marginless">
              <strong>From: {account.firstName}</strong>
            </p>
          </div>
        </div>
        <footer className="padding-3 is-flex has-border-top-gray">
          <button
            className="button is-dark-gray margin-right-1"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            className="button is-yellow is-flex-1 is-align-items-flex-end"
            onClick={handleInvite}
            disabled={isLoading || !isAvailable}
          >
            Send Invite Link
            <SVG
              height={20}
              className="padding-left-1"
              path="site/icon/share-curved-arrow"
              alt="Share"
            />
          </button>
        </footer>
      </div>
    </Container>
  );
};

export { InviteFriends };
