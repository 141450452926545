import { ClientService } from "./ClientService";
import { FC, PropsWithChildren } from "react";
import { ExternalLink } from "react-components";
import { Link } from "react-router-dom";

interface Props {
  bookingNumber: string;
}

const AnemicNavigation: FC<Props> = ({ bookingNumber }) => (
  <>
    <hr className="divider" />

    <MinimalNavigation>
      <li className="has-text-light-blue margin-y-3">
        <ExternalLink
          className="is-undecorated-link"
          href={`${process.env.WWW_ORIGIN}/caravan-travel-protection.pdf`}
        >
          <strong>$129 Travel Protection</strong>
        </ExternalLink>
      </li>

      <li className="has-text-light-blue margin-y-3">
        <ExternalLink
          className="is-undecorated-link"
          href={`${process.env.WWW_ORIGIN}/caravan-terms-of-travel.pdf`}
        >
          <strong>Terms of Travel</strong>
        </ExternalLink>
      </li>

      <li className="has-text-light-blue margin-y-3">
        <Link
          className="is-undecorated-link"
          to={`/bookings/${bookingNumber}/faqs`}
        >
          <strong>Booking FAQs</strong>
        </Link>
      </li>

      {/* <li className="has-text-light-blue margin-y-3">
        <Link
          className="is-undecorated-link"
          to={`/bookings/${bookingNumber}/cancel`}
        >
          <strong>Cancel Options</strong>
        </Link>
      </li> */}
    </MinimalNavigation>
  </>
);

export { AnemicNavigation };

const MinimalNavigation: FC<PropsWithChildren> = ({ children }) => (
  <ul className="has-list-style-type-none padding-left-0 is-marginless">
    <li className="margin-y-3">
      <strong className="has-text-light-blue">Help?</strong>{" "}
      <ClientService>Client Service 1-800-CARAVAN</ClientService>
    </li>
    {children}
  </ul>
);

export { MinimalNavigation };
