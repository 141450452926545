import { Container, AuxContainer } from "../elements";
import { ExternalLink } from "react-components";
import { FC } from "react";

const SurveySubmitted: FC = () => (
  <>
    <Container>
      <h1 className="is-marginless">Thank You!</h1>
      <hr className="divider is-red" />

      <p>Your submission has been received.</p>
    </Container>
    <AuxContainer>
      <ExternalLink
        className="button is-yellow is-fullwidth"
        href={process.env.WWW_ORIGIN}
      >
        Go to Caravan.com
      </ExternalLink>
    </AuxContainer>
  </>
);

export { SurveySubmitted };
