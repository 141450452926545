import { parseHoldSpaceParams } from "../utils";
import { AccountContext, ACCOUNT_KEY } from "../context";
import { holdSpace } from "../api";
import { hasReachedHoldLimit } from "data-model";
import { useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { set } from "idb-keyval";

const HoldSpace = () => {
  const [searchParams] = useSearchParams();
  const holdSpaceReq = parseHoldSpaceParams(searchParams);
  const search = holdSpaceReq ? `?${searchParams.toString()}` : undefined;

  const navigate = useNavigate();
  const [account, setAccount] = useContext(AccountContext);

  useEffect(() => {
    (async () => {
      if (!account) {
        return navigate(
          {
            pathname: "/login",
            search,
          },
          {
            replace: true,
          }
        );
      }

      if (!holdSpaceReq) {
        return navigate(
          {
            pathname: "/bookings",
            search,
          },
          {
            replace: true,
          }
        );
      }

      const { holdId } = holdSpaceReq;
      if (!holdId && hasReachedHoldLimit(account.bookings)) {
        return navigate(
          {
            pathname: "/hold-limit",
            search,
          },
          {
            replace: true,
          }
        );
      }

      try {
        const hold = await holdSpace(holdSpaceReq);

        const updatedAccount = {
          ...account,
          bookings: [hold, ...account.bookings],
        };

        if (holdId) {
          updatedAccount.bookings = updatedAccount.bookings.map((b) =>
            // not exact deletedAt, but close enough
            b.id === holdId ? { ...b, deletedAt: hold.createdAt } : b
          );
        }

        await set(ACCOUNT_KEY, updatedAccount);

        setAccount(updatedAccount);

        window.pintrk("track", "holdspace");

        navigate(`/bookings?${holdId ? "replaced" : "holding"}`, {
          replace: true,
        });
      } catch (e) {
        if (e instanceof Response && e.status === 422) {
          navigate(
            {
              pathname: "/space-unavailable",
              search,
            },
            {
              replace: true,
            }
          );
        } else {
          // TODO 500 or other
        }
      }
    })();
  }, []);

  return null;
};

export { HoldSpace };
