import { AuxContainer, Container } from "../elements";
import { Link } from "react-router-dom";

const InvalidLink = () => (
  <>
    <Container>
      <h1 className="is-marginless">Invalid Password Reset Link</h1>
      <hr className="divider is-red" />

      <p>
        Sorry, your password reset link has either expired or is invalid. You
        may request another link by returning to the Log In page.
      </p>
    </Container>
    <AuxContainer>
      <Link className="button is-yellow is-fullwidth" to="/login">
        Back to Log In
      </Link>
    </AuxContainer>
  </>
);

export { InvalidLink };
