import { PropsWithChildren, FC } from "react";
import clsx from "clsx";

interface Props extends PropsWithChildren {
  className?: string;
}

const Container: FC<Props> = ({ children, className }) => (
  <div
    className={clsx(
      "container is-flex-1-mobile is-fullwidth padding-y-2 padding-x-2-mobile",
      className
    )}
  >
    {children}
  </div>
);

interface AuxProps extends PropsWithChildren {
  className?: string;
}

const AuxContainer: FC<AuxProps> = ({ children, className }) => (
  <div
    className={clsx(
      "container is-fullwidth padding-top-2 padding-x-2-mobile padding-bottom-4",
      className
    )}
  >
    {children}
  </div>
);

export { Container, AuxContainer };
