import { AccountContext } from "../context";
import { publicTourIds, db } from "../jsongo";
import {
  AccountDO,
  BookingDO,
  BookingHoldSpaceReq,
  sumNumbers,
  MAX_GUESTS,
  isTooLateToBook,
  validateBookingNumber,
  isSerialId,
  yearFromDate,
  TourYearDO,
  yyyy_MM_dd,
} from "data-model";
import { DateTime } from "luxon";
import { FC, ComponentType, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";

export interface ExistingBookingProps {
  account: AccountDO;
  booking: BookingDO;
}

export const ExistingBooking: FC<{
  component: ComponentType<ExistingBookingProps>;
}> = ({ component: Component }) => {
  const { bookingNumber } = useParams();

  const [account] = useContext(AccountContext);
  if (!account) throw new Error("Unauthenticated");

  const booking = account.bookings.find((b) => b.number === bookingNumber);
  if (!booking) {
    // TODO show an error page instead?
    return <Navigate to="/bookings" />;
  }

  return <Component account={account} booking={booking} />;
};

export function parseHoldSpaceParams(
  params: URLSearchParams
): BookingHoldSpaceReq | null {
  const tour = params.get("t");
  const roomGuests = decodeURIComponent(params.get("r") || "");
  const date = params.get("d");
  const number = params.get("b") || undefined;
  const holdId = params.get("h") || undefined;

  let departedAt;
  try {
    departedAt = DateTime.fromFormat(date!, yyyy_MM_dd);
  } catch {} // eslint-disable-line no-empty

  if (
    !tour ||
    !publicTourIds.includes(tour) ||
    !roomGuests ||
    !/^[1-3](,[1-3])?(,[1-3])?$/.test(roomGuests) ||
    roomGuests.split(",").map(Number).reduce(sumNumbers) > MAX_GUESTS ||
    !date ||
    !departedAt ||
    !departedAt.isValid ||
    !(departedAt > DateTime.now())
  ) {
    return null;
  }

  const year = yearFromDate(date);
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year,
  }) as TourYearDO;
  if (!tourYear) return null;

  if (
    isTooLateToBook(date) ||
    (number && !validateBookingNumber(number, date)) ||
    (holdId && !isSerialId(holdId))
  ) {
    return null;
  }

  return {
    tour,
    roomGuests: roomGuests.split(",").map(Number),
    date,
    number,
    holdId: holdId ? Number(holdId) : undefined,
  };
}

export function parseTourNoticeParams(params: URLSearchParams) {
  const tour = params.get("t");
  const year = params.get("n");

  let date;
  try {
    date = DateTime.fromFormat(year!, "yyyy");
  } catch {} // eslint-disable-line no-empty

  if (
    !tour ||
    !publicTourIds.includes(tour) ||
    !year ||
    !date ||
    !date.isValid ||
    !db.tour.exists({ _id: tour, upcomingYears: +year })
  ) {
    return null;
  }

  return { tour, year: +year };
}
