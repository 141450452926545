import { FlightSearch } from "./FlightSearch";
import { OtherPlans } from "./OtherPlans";
import { makeDraftFrom } from "../steps";
import { hotelsById } from "../../../jsongo";
import {
  TransportDraft,
  TourYearDO,
  directionLowerFrom,
  TransportDO_Means,
  flightOrOther,
  capitalize,
  TransportDO,
  hotelOnLastDay,
  hotelOnFirstDay,
  arrivesEarly,
  departsLate,
  draftDefaults,
  DepartureDO,
} from "data-model";
import { Input, Radio, SVG } from "react-components";
import { FC, ChangeEvent } from "react";
import clsx from "clsx";

interface Props {
  departure: DepartureDO;
  draft: TransportDraft; // current transport as it's edited
  isUnlocked: boolean;
  onDraftChange: (
    updates: Partial<TransportDraft>,
    posIdx: number,
    isArrival: boolean
  ) => void;
  positionalIdx: number;
  tourYear: TourYearDO;
  transport?: TransportDO; // saved transport, if any
}

const FlightEditor: FC<Props> = (props) => {
  const {
    departure,
    draft,
    onDraftChange,
    isUnlocked,
    positionalIdx,
    tourYear,
    transport,
  } = props;
  const { departedAt, concludedAt } = departure;

  const { isArrival, isFlight } = draft;
  const direction = directionLowerFrom(isArrival);
  const idPrefix = `${positionalIdx}-${direction}`;
  const preOrPost = isArrival ? "pre" : "post";
  const hotelName = isArrival
    ? hotelOnFirstDay(hotelsById, tourYear, departure)
    : hotelOnLastDay(hotelsById, tourYear, departure);

  const handleMeansChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isFlightSelected = e.currentTarget.value === TransportDO_Means.FLIGHT;

    onDraftChange(
      transport?.isFlight === isFlightSelected
        ? makeDraftFrom(transport)
        : {
            ...draftDefaults,
            isArrival,
            isFlight: isFlightSelected,
            scheduledDate: isArrival ? departedAt : concludedAt,
          },
      positionalIdx,
      isArrival
    );
  };

  const handleStayChange = (e: ChangeEvent<HTMLInputElement>) =>
    onDraftChange(
      { stayAtCaravanHotel: e.currentTarget.value === "yes", stayNote: "" },
      positionalIdx,
      isArrival
    );

  return (
    <>
      <p className="margin-y-2">
        1. How are you {isArrival ? "arriving at" : "departing from"} tour?
      </p>

      <div className="margin-left-3">
        <div className="is-flex">
          {[TransportDO_Means.FLIGHT, TransportDO_Means.OTHER].map(
            (value, idx) => (
              <Radio
                key={idx}
                id={`${idPrefix}-${value}`}
                parentClassName={clsx(idx === 0 && "margin-right-4")}
                disabled={!isUnlocked}
                value={value}
                checked={flightOrOther(draft) === value}
                onChange={handleMeansChange}
              >
                {capitalize(value.toLowerCase())}
                {value === TransportDO_Means.OTHER && " (car, train, etc.)"}
              </Radio>
            )
          )}
        </div>

        {!isFlight && (
          <Input
            id={`${idPrefix}-travel-note`}
            parentClassName="margin-top-2"
            label={`How are you ${
              isArrival ? "arriving" : "departing"
            }? (Optional)`}
            maxLength={40}
            value={draft.travelNote || ""}
            onChange={(e) =>
              onDraftChange(
                { travelNote: e.currentTarget.value },
                positionalIdx,
                isArrival
              )
            }
          />
        )}
      </div>

      {isFlight ? (
        <>
          <p className="margin-top-3 margin-bottom-2">
            2. Enter {isArrival ? "last" : "first"} leg of your flight{" "}
            {isArrival ? "arriving at" : "departing"} tour
          </p>

          <div className="margin-left-3 margin-bottom-1">
            <FlightSearch {...props} idPrefix={`${idPrefix}-flight`} />
          </div>
        </>
      ) : (
        <>
          <p className="margin-top-3 margin-bottom-2">
            2. Enter date you are{" "}
            {isArrival ? "arriving for" : "departing from"} this tour
          </p>

          <div className="margin-left-3 margin-bottom-1">
            <OtherPlans {...props} idPrefix={`${idPrefix}-other`} />
          </div>
        </>
      )}

      {(arrivesEarly(isArrival, draft.scheduledDate, departedAt) ||
        departsLate(isArrival, draft.scheduledDate, concludedAt)) &&
        (draft.extraNight ? (
          <p className="is-flex is-align-items-flex-start margin-top-2 margin-bottom-0 margin-left-3">
            <SVG
              path="site/icon/info-black"
              alt="Info"
              className="margin-right-1"
              height={18}
            />
            Caravan to provide one {preOrPost}-tour night at {hotelName} and
            airport transfer
          </p>
        ) : (
          <>
            <p className="margin-top-3 margin-bottom-2">
              3. You need to arrange a {preOrPost}-tour stay. Will you stay at
              Caravan&apos;s {isArrival ? "starting" : "ending"} hotel?
            </p>

            <div className="margin-left-3">
              {[
                [
                  "yes",
                  `Yes, I will book additional night(s) at ${hotelName} on my own`,
                ],
                ["no", `No, I will arrange a different ${preOrPost}-tour stay`],
              ].map(([value, label], idx) => (
                <Radio
                  key={idx}
                  id={`${idPrefix}-${value}`}
                  parentClassName="margin-bottom-2 is-align-items-flex-start"
                  labelClassName="is-line-height-normal-mobile"
                  disabled={!isUnlocked}
                  value={value}
                  checked={
                    typeof draft.stayAtCaravanHotel === "boolean"
                      ? draft.stayAtCaravanHotel
                        ? value === "yes"
                        : value === "no"
                      : false
                  }
                  onChange={handleStayChange}
                  inline={false}
                >
                  {label}
                </Radio>
              ))}

              {draft.stayAtCaravanHotel === false && (
                <Input
                  id={`${idPrefix}-stay-note`}
                  label="Where are you staying? (Optional)"
                  maxLength={40}
                  value={draft.stayNote || ""}
                  onChange={(e) =>
                    onDraftChange(
                      { stayNote: e.currentTarget.value },
                      positionalIdx,
                      isArrival
                    )
                  }
                />
              )}
            </div>
          </>
        ))}
    </>
  );
};

export { FlightEditor, Props as FlightEditorProps };
