import { AccountContext } from "../context";
import { Container } from "../elements";
import { useContext } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [account] = useContext(AccountContext);
  return (
    <Container>
      <h1 className="is-marginless">Page Not Found</h1>
      <hr className="divider is-red" />

      <p>
        Sorry, there&apos;s no page with that name on Caravan&apos;s site.
        Return to{" "}
        {account ? (
          <Link to="/bookings">Bookings</Link>
        ) : (
          <Link to="/login">Login</Link>
        )}
        .
      </p>
    </Container>
  );
};

export { NotFound };
