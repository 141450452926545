import { AccountContext, ACCOUNT_KEY } from "../context";
import { ClientService, SmsChallenge, TravelAgentNotice } from "../components";
import { Container, AuxContainer, Notification } from "../elements";
import { Password } from "../form";
import { logIn, recoverPassword, resetEmail, sendCode } from "../api";
import { parseHoldSpaceParams, parseTourNoticeParams } from "../utils";
import {
  ErrorMessage,
  hasError,
  Input,
  PhoneNumber,
  SVG,
  useErrors,
  useModal,
} from "react-components";
import { FC, FormEvent, useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { set } from "idb-keyval";

const LogIn: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setModal = useModal();
  const [, setAccount] = useContext(AccountContext);
  const [errors, catchErrors] = useErrors();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { search } = location;
  const params = new URLSearchParams(search);
  const holdSpaceReq = parseHoldSpaceParams(params);
  const tourNoticeReq = parseTourNoticeParams(params);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    catchErrors(
      async () => {
        setIsLoading(true);

        const account = await logIn(email, password);

        await set(ACCOUNT_KEY, account);

        if (tourNoticeReq) {
          window.location.href = `${process.env.WWW_ORIGIN}/tour/${tourNoticeReq.tour}?n=${tourNoticeReq.year}`;
        } else {
          setAccount(account);

          navigate(holdSpaceReq ? { pathname: "/", search } : "/bookings", {
            replace: true,
          });
        }
      },
      () => setIsLoading(false)
    );
  };

  return (
    <form className="is-flex-column is-flex-1" onSubmit={handleLogin}>
      <Container>
        <h1 className="is-marginless is-flex">
          <SVG
            className="margin-right-2"
            path="site/icon/person-black"
            alt="Person"
            height={20}
          />
          <span className="is-flex-1">Log In</span>
          <TravelAgentNotice />
        </h1>
        <hr className="divider is-red" />

        {(holdSpaceReq || tourNoticeReq) && (
          <p className="margin-y-2 has-text-red">
            <strong>
              Please Log In or Sign Up{" "}
              {holdSpaceReq ? "to hold space" : "for Email Notice"}
            </strong>
          </p>
        )}

        <p className="margin-y-2">
          Don&apos;t have an account?{" "}
          <Link
            to={{
              pathname: "/signup",
              search,
            }}
          >
            <strong>Sign up</strong>
          </Link>
        </p>
        <Input
          id="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "email")}
        />
        <Password
          id="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "password")}
        />

        <ErrorMessage errors={errors} className="margin-top-2" />

        <p>
          <button
            type="button"
            className="button is-ghost is-link is-paddingless"
            onClick={() =>
              setModal({
                isOpen: true,
                body: <ForgotPassword />,
                className: "is-fullsize-desktop",
              })
            }
          >
            Forgot Email or Password?
          </button>
        </p>
      </Container>
      <AuxContainer>
        <button
          type="submit"
          className="button is-fullwidth is-yellow"
          disabled={!email || !password || isLoading}
        >
          Log In{holdSpaceReq ? " and Hold Space" : ""}
        </button>
      </AuxContainer>
    </form>
  );
};

export { LogIn };

const ForgotPassword: FC = () => {
  const setModal = useModal();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, catchErrors] = useErrors();

  const handleEmailLink = async () =>
    catchErrors(
      async () => {
        setIsLoading(true);

        await recoverPassword(email);

        setIsEmailSent(true);
      },
      () => setIsLoading(false)
    );

  const handleModalClose = () =>
    setModal({ isOpen: false, body: null, className: "" });

  const handleForgotEmail = (phoneNumber?: string) =>
    setModal({
      isOpen: true,
      body: (
        <ForgotEmail onCodeSend={handleCodeSend} phoneNumber={phoneNumber} />
      ),
      className: "is-fullsize-desktop",
    });

  const handleCodeSend = (phoneNumber: string) =>
    setModal({
      isOpen: true,
      body: (
        <SmsChallenge
          phoneNumber={phoneNumber}
          onReenter={() => {
            handleForgotEmail(phoneNumber);
            setTimeout(() => document.getElementById(phoneNumberId)?.focus());
          }}
          onVerify={async (smsCode) => {
            await resetEmail({ phoneNumber, smsCode }); // dry run to verify SMS code
            handleCodeVerify(phoneNumber, smsCode);
          }}
        />
      ),
      className: "is-fullsize-desktop",
    });

  const handleCodeVerify = (phoneNumber: string, smsCode: string) =>
    setModal({
      isOpen: true,
      body: (
        <ResetEmail
          phoneNumber={phoneNumber}
          smsCode={smsCode}
          onReset={(newEmail) =>
            setModal({
              isOpen: true,
              body: (
                <Notification
                  title="Email Updated!"
                  body={
                    <>
                      <p>
                        Your new email address is confirmed. Your phone number
                        on file is {phoneNumber}
                      </p>

                      <p>
                        <strong>{newEmail}</strong>
                      </p>
                    </>
                  }
                  onClose={handleModalClose}
                />
              ),
              className: "is-fullsize-desktop",
            })
          }
        />
      ),
      className: "is-fullsize-desktop",
    });

  return (
    <>
      <h1 id="modal-title" className="is-size-2">
        {isEmailSent ? "Link Sent!" : "Reset Password"}
      </h1>
      {isEmailSent ? (
        <div id="modal-description">
          <p>A link to reset your password has been successfully emailed to:</p>

          <p>
            <strong>{email}</strong>
          </p>

          <p>
            Didn&apos;t get the link?{" "}
            <button
              className="button is-ghost is-link is-paddingless is-inline-flex"
              onClick={handleEmailLink}
              disabled={isLoading}
            >
              Send again
            </button>
          </p>

          <p className="margin-bottom-5">
            Wrong email address?{" "}
            <button
              className="button is-ghost is-link is-paddingless is-inline-flex"
              onClick={() => {
                setIsEmailSent(false);
                setEmail("");
              }}
              disabled={isLoading}
            >
              Re-enter email
            </button>
          </p>

          <button
            className="button is-fullwidth is-yellow"
            disabled={isLoading}
            onClick={() =>
              setModal({ isOpen: false, body: null, className: "" })
            }
          >
            OK
          </button>
        </div>
      ) : (
        <form
          id="modal-description"
          onSubmit={(e) => {
            e.preventDefault();
            handleEmailLink();
          }}
        >
          <p>
            Enter the email address associated with your account, and we will
            email you a link to reset your password.
          </p>

          <Input
            id="email"
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            parentClassName="margin-y-1"
            invalid={hasError(errors, "email")}
          />

          <ErrorMessage errors={errors} className="margin-top-2" />

          <p className="margin-bottom-5">
            Forgot email?{" "}
            <button
              type="button"
              className="button is-ghost is-link is-paddingless is-inline-flex"
              onClick={() => handleForgotEmail()}
            >
              Reset email address
            </button>
          </p>

          <button
            type="submit"
            className="button is-fullwidth is-yellow"
            disabled={email.length === 0 || isLoading}
          >
            Email Link to Reset Password
          </button>
        </form>
      )}
    </>
  );
};

const phoneNumberId = "phone-number";

interface ForgotEmailProps {
  onCodeSend: (phoneNumber: string) => void;
  phoneNumber?: string;
}

const ForgotEmail: FC<ForgotEmailProps> = ({
  onCodeSend,
  phoneNumber: initPhoneNo = "",
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initPhoneNo);
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const handleTextCode = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    catchErrors(
      async () => {
        await resetEmail({ phoneNumber }); // dry run to check that the account exists
        await sendCode({ phoneNumber });
        onCodeSend(phoneNumber);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h1 id="modal-title" className="is-size-2">
        Reset Email
      </h1>
      <form id="modal-description" onSubmit={handleTextCode}>
        <p>
          Enter the phone number associated with your account to update your
          email.
        </p>

        <PhoneNumber
          id={phoneNumberId}
          value={phoneNumber}
          onChange={setPhoneNumber}
          containerClass="margin-y-1"
          invalid={hasError(errors, "phoneNumber")}
          specialLabel="Mobile Phone"
        />

        <ErrorMessage errors={errors} className="margin-top-2" />

        <p>
          Forgot both email and phone number?
          <br />
          <ClientService />
        </p>

        <button
          type="submit"
          className="button is-fullwidth is-yellow"
          disabled={isLoading || !phoneNumber}
        >
          Text Code to Reset Email
        </button>
      </form>
    </>
  );
};

interface ResetEmailProps {
  onReset: (newEmail: string) => void;
  phoneNumber: string;
  smsCode: string;
}

const ResetEmail: FC<ResetEmailProps> = ({ onReset, phoneNumber, smsCode }) => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    catchErrors(
      async () => {
        await resetEmail({ phoneNumber, smsCode, email });
        onReset(email);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h1 id="modal-title" className="is-size-2">
        Reset Email
      </h1>
      <form id="modal-description" onSubmit={handleSave}>
        <p>
          Enter your new email address. Your phone number on file is{" "}
          {phoneNumber}
        </p>

        <Input
          id="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "email")}
        />

        <Input
          id="email"
          type="email"
          label="Confirm Email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.currentTarget.value)}
          parentClassName="margin-y-1"
        />

        <ErrorMessage errors={errors} className="margin-top-2" />

        <button
          type="submit"
          className="button is-fullwidth is-yellow margin-top-3"
          disabled={
            isLoading || !email || !confirmEmail || email !== confirmEmail
          }
        >
          Save New Email
        </button>
      </form>
    </>
  );
};
