import { clientServiceModal } from "./ClientService";
import { AccountContext } from "../context";
import { globalDO, publicToursById } from "../jsongo";
import { parseHoldSpaceParams } from "../utils";
import { useModal, isBrowser, Header as RcHeader } from "react-components";
import { FC, useState, useEffect, MouseEvent, useContext } from "react";
import { useSearchParams } from "react-router-dom";

const Header: FC = () => {
  const [account] = useContext(AccountContext);
  const setModal = useModal();

  const [searchParams] = useSearchParams();
  const holdSpaceReq = parseHoldSpaceParams(searchParams);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  let mobileMediaQuery: MediaQueryList;
  let desktopMediaQuery: MediaQueryList;
  let printMediaQuery: MediaQueryList;
  if (isBrowser()) {
    mobileMediaQuery = window.matchMedia("screen and (max-width: 654px)");
    desktopMediaQuery = window.matchMedia("screen and (min-width: 655px)");
    printMediaQuery = window.matchMedia("print");
  }

  const isMobile = () => mobileMediaQuery.matches;

  useEffect(() => {
    const onMobileMediaChange = () => {
      if (isMobile() && isPopoverOpen) {
        setIsPopoverOpen(false);
        setIsDrawerOpen(true);
      }
    };
    const onDesktopMediaChange = () => {
      if (desktopMediaQuery.matches && isDrawerOpen) {
        setIsDrawerOpen(false);
        setIsPopoverOpen(true);
      }
    };
    const onPrintMediaChange = () => {
      if (printMediaQuery.matches) {
        if (isDrawerOpen) setIsDrawerOpen(false);
        else if (isPopoverOpen) setIsPopoverOpen(false);
      }
    };

    mobileMediaQuery.addListener(onMobileMediaChange);
    desktopMediaQuery.addListener(onDesktopMediaChange);
    printMediaQuery.addListener(onPrintMediaChange);

    return () => {
      mobileMediaQuery.removeListener(onMobileMediaChange);
      desktopMediaQuery.removeListener(onDesktopMediaChange);
      printMediaQuery.removeListener(onPrintMediaChange);
    };
  }, [isPopoverOpen, isDrawerOpen]);

  const handleCallLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // won't be needed after we change <a href="tel"> => <button>
    setModal(clientServiceModal);
  };

  const handleMenuToggle = () =>
    isMobile()
      ? setIsDrawerOpen(!isDrawerOpen)
      : setIsPopoverOpen(!isPopoverOpen);

  return (
    <RcHeader
      publicTourList={globalDO.publicTourList}
      tourDOsById={publicToursById}
      hideNavLinks={!!holdSpaceReq}
      homeLinkHref={process.env.WWW_ORIGIN}
      bookingLinkHref={account ? "/bookings" : "/login"} // not appending `search` b/c the nav links are hidden
      callLinkProps={{
        href: "#",
        onClick: handleCallLink,
      }}
      onMenuBtnClick={handleMenuToggle}
      drawerProps={{
        isOpen: isDrawerOpen,
        onClose: handleMenuToggle,
      }}
      popoverProps={{
        isOpen: isPopoverOpen,
        onClose: handleMenuToggle,
      }}
      tourLinkBaseUrl={process.env.WWW_ORIGIN}
    />
  );
};

export { Header };
