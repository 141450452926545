import { Box, BoxHeading, CommonProps } from "./shared";
import { editGuestDetails } from "../../../api";
import { AccountContext, ACCOUNT_KEY } from "../../../context";
import { ClientService } from "../../../components";
import {
  arrOfSize,
  fullNameOrPlaceholder,
  isGuestAccountOwner,
  isGuestDetailsComplete,
  countriesByCode,
  regionsByCountryCode,
  sortRegionsAlpha,
  passportMustExpireAfter,
  passportRequiresRenewal,
  pickBy,
  primaryGuestPosIdx,
  trim,
  TourYearDO,
  GuestDetails,
  GuestDO,
  AccountDO,
  primaryGuestInfo,
  AddressDO,
} from "data-model";
import {
  Checkbox,
  Datepicker,
  Input,
  PassportExpires,
  PassportNotice,
  PhoneNumber,
  Select,
  Textarea,
} from "react-components";
import { FC, useState, FormEvent, useContext } from "react";
import { klona } from "klona/json";
import deepEqual from "fast-deep-equal";
import { set } from "idb-keyval";

const context = "stepper-guest-details";

const ages17to5 = arrOfSize(13).map((_, idx) => 17 - idx);

interface Props extends CommonProps {
  tourYear: TourYearDO;
}

const GuestDetails: FC<Props> = ({
  account,
  booking,
  isUnlocked,
  onClose,
  tourYear,
}) => {
  const isLocked = !isUnlocked;
  const { guests, id: bookingId } = booking;
  // Names don't change in Step 3, so a lookup on booking.guests is adequate.
  const primaryPosIdx = primaryGuestPosIdx(guests, account);
  const primaryFullName = fullNameOrPlaceholder(guests, account, primaryPosIdx);
  const init = initGuestDetailsAndSameAs(guests, account, tourYear);

  const [, setAccount] = useContext(AccountContext);
  const [guestDetails, setGuestDetails] = useState(init.guestDetails);
  const [sameAs, setSameAs] = useState(init.sameAs); // address OR emergency contact

  const handleDetailsChange = (newDetails: GuestDetails, posIdx: number) => {
    const newGuestDetails = klona(guestDetails);

    if (posIdx === primaryPosIdx) {
      const primaryDetails = guestDetails[posIdx];
      // Whenever primary address or emergency contact changes,
      // update all guests that have Same As checked on
      if (
        !deepEqual(primaryDetails.address, newDetails.address) ||
        primaryDetails.emergencyContact !== newDetails.emergencyContact
      ) {
        newGuestDetails.forEach((details, otherPosIdx) => {
          if (otherPosIdx === primaryPosIdx) return;
          if (sameAs[otherPosIdx]) {
            details.address = newDetails.address;
            details.emergencyContact = newDetails.emergencyContact;
          }
        });
      }
    }

    newGuestDetails[posIdx] = newDetails;

    setGuestDetails(newGuestDetails);
  };

  const handleSameAsChange = (newIsSame: boolean, posIdx: number) => {
    setSameAs(
      sameAs.map((currIsSame, i) => (i === posIdx ? newIsSame : currIsSame))
    );

    // When Same is checked on, assign address and contact to primary. Otherwise, reset it.
    const primaryDetails = guestDetails[primaryPosIdx];
    const address = newIsSame
      ? primaryDetails.address
      : resetAddress(guestDetails[posIdx].address);
    const emergencyContact = newIsSame ? primaryDetails.emergencyContact : "";

    setGuestDetails(
      guestDetails.map((currDetails, i) =>
        i === posIdx
          ? { ...currDetails, address, emergencyContact }
          : currDetails
      )
    );
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const guests = await editGuestDetails(bookingId, trim(guestDetails));

    const updatedAccount = {
      ...account,
      bookings: account.bookings.map((b) => {
        if (b.id === bookingId) {
          return {
            ...b,
            guests: b.guests.map((g, posIdx) => {
              const updatedGuest = guests[posIdx];
              return {
                ...g,
                // Exclude unpopulated fields like arrival and departure
                ...pickBy(updatedGuest, [
                  "address",
                  "email",
                  "phoneNumber",
                  "age",
                  "emergencyContact",
                  "passportNumber",
                  "passportCountry",
                  "passportBornAt",
                  "passportExpiredAt",
                  "updatedAt",
                ]),
              };
            }),
          };
        }
        return b;
      }),
    };

    await set(ACCOUNT_KEY, updatedAccount);

    setAccount(updatedAccount);

    onClose();
  };

  return (
    <>
      <form onSubmit={handleSave}>
        {guestDetails.map((details, posIdx) => (
          <GuestBox
            key={posIdx}
            concludedAt={booking.departure.concludedAt}
            details={details}
            fullName={fullNameOrPlaceholder(guests, account, posIdx)}
            isLocked={isLocked}
            isOwner={isGuestAccountOwner(guests, account, posIdx)}
            isPrimary={posIdx === primaryPosIdx}
            onDetailsChange={handleDetailsChange}
            onSameAsChange={handleSameAsChange}
            posIdx={posIdx}
            primaryDetails={guestDetails[primaryPosIdx]}
            primaryFullName={primaryFullName}
            sameAs={sameAs}
            tourYear={tourYear}
          />
        ))}

        <p className="margin-top-2 margin-bottom-3">
          For all room requests, other special requests, or accessibility
          requests, please contact Caravan&apos;s{" "}
          {isLocked ? (
            <strong>Client Service</strong>
          ) : (
            <ClientService>Client Service</ClientService>
          )}
          .
        </p>

        <button className="button is-yellow is-fullwidth" disabled={isLocked}>
          Save and Continue
        </button>
      </form>
    </>
  );
};

interface GuestBoxProps {
  concludedAt: string;
  details: GuestDetails;
  fullName: string;
  isLocked: boolean;
  isOwner: boolean;
  isPrimary: boolean;
  onDetailsChange: (details: GuestDetails, posIdx: number) => void;
  onSameAsChange: (isSame: boolean, posIdx: number) => void;
  posIdx: number;
  primaryDetails: GuestDetails;
  primaryFullName: string;
  sameAs: boolean[];
  tourYear: TourYearDO;
}

const GuestBox: FC<GuestBoxProps> = ({
  concludedAt,
  details,
  fullName,
  isLocked,
  isOwner,
  isPrimary,
  onDetailsChange,
  onSameAsChange,
  posIdx,
  primaryDetails,
  primaryFullName,
  sameAs,
  tourYear,
}) => {
  const idPrefix = `${context}-${posIdx}`;
  const parentClassName = "margin-y-1";
  const { address } = details;
  const isSameAs = sameAs[posIdx];
  const isUnder18 = !!details.age;

  const passportMustExpAfter = passportMustExpireAfter(concludedAt);
  const mustRenewPassport = passportRequiresRenewal(
    passportMustExpAfter,
    details.passportExpiredAt
  );

  return (
    <Box key={posIdx} className="margin-bottom-2">
      <BoxHeading
        className="margin-bottom-2"
        isUnlocked={!isLocked}
        showPerson={isOwner}
        showCheckmark={isGuestDetailsComplete(
          trim(details) as GuestDO,
          tourYear,
          concludedAt,
          // Technically, unnecessary because details are already autofilled
          primaryDetails as GuestDO,
          primaryDetails.address as AddressDO
        )}
      >
        {fullName}
      </BoxHeading>
      <PhoneNumber
        id={`${idPrefix}-phone`}
        containerClass={parentClassName}
        specialLabel={`Mobile Phone${
          isUnder18 ? " (Optional for children under 18)" : ""
        }`}
        disabled={isLocked}
        value={details.phoneNumber}
        onChange={(phoneNumber) =>
          onDetailsChange({ ...details, phoneNumber }, posIdx)
        }
      />
      <Input
        id={`${idPrefix}-email`}
        label={`Email${isUnder18 ? " (Optional for children under 18)" : ""}`}
        parentClassName={parentClassName}
        disabled={isLocked}
        value={details.email}
        onChange={(e) =>
          onDetailsChange({ ...details, email: e.currentTarget.value }, posIdx)
        }
      />

      {!isOwner && (
        <Select
          id={`${idPrefix}-age`}
          label={`Age at the time of travel${isUnder18 ? "" : ": 18+ years"}`}
          value={details.age ? `${details.age}` : ""}
          disabled={isLocked}
          onChange={(e) => {
            const age = e.currentTarget.value;
            onDetailsChange(
              { ...details, age: age === "" ? undefined : +age },
              posIdx
            );
          }}
        >
          <option disabled hidden value="" />
          <option value="">18+ years</option>
          {ages17to5.map((age) => (
            <option key={age} value={age}>
              {age} years
            </option>
          ))}
          <option disabled>
            Children age 5 and over are welcome on tour when accompanied by an
            adult.
          </option>
        </Select>
      )}

      {!isPrimary && (
        <Checkbox
          id={`${idPrefix}-other`}
          parentClassName={isSameAs ? "margin-top-2" : "margin-y-2"}
          labelClassName="is-line-height-normal-mobile"
          disabled={isLocked}
          checked={isSameAs}
          onChange={() => onSameAsChange(!isSameAs, posIdx)}
        >
          Same address and emergency contact as {primaryFullName}
        </Checkbox>
      )}
      {(isPrimary || !isSameAs) && (
        <>
          <Input
            id={`${idPrefix}-line1`}
            label="Street Address"
            parentClassName={parentClassName}
            disabled={isLocked}
            value={address.line1}
            onChange={(e) =>
              onDetailsChange(
                {
                  ...details,
                  address: { ...address, line1: e.currentTarget.value },
                },
                posIdx
              )
            }
          />
          <Input
            id={`${idPrefix}-line2`}
            label="Apartment/Unit/Suite (Optional)"
            parentClassName={parentClassName}
            disabled={isLocked}
            value={address.line2}
            onChange={(e) =>
              onDetailsChange(
                {
                  ...details,
                  address: { ...address, line2: e.currentTarget.value },
                },
                posIdx
              )
            }
          />
          <div className={`is-flex is-column-gap-1 ${parentClassName}`}>
            <Input
              id={`${idPrefix}-city`}
              label="City"
              parentClassName="is-flex-1"
              disabled={isLocked}
              value={address.city}
              onChange={(e) =>
                onDetailsChange(
                  {
                    ...details,
                    address: { ...address, city: e.currentTarget.value },
                  },
                  posIdx
                )
              }
            />
            <Select
              id={`${idPrefix}-state`}
              label="State/Province"
              parentClassName="is-flex-1"
              disabled={isLocked}
              value={address.state}
              onChange={(e) =>
                onDetailsChange(
                  {
                    ...details,
                    address: { ...address, state: e.currentTarget.value },
                  },
                  posIdx
                )
              }
            >
              <option value="" />
              {address.country &&
                Object.entries(regionsByCountryCode[address.country])
                  .sort(sortRegionsAlpha)
                  .map(([code, name]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
            </Select>
          </div>
          <div className={`is-flex is-column-gap-1 ${parentClassName}`}>
            <Input
              id={`${idPrefix}-zip`}
              label="Zip/Postal Code"
              parentClassName="is-flex-1"
              minLength={1}
              maxLength={10}
              disabled={isLocked}
              value={address.zip}
              onChange={(e) =>
                onDetailsChange(
                  {
                    ...details,
                    address: { ...address, zip: e.currentTarget.value },
                  },
                  posIdx
                )
              }
            />
            <Select
              id={`${idPrefix}-country`}
              label="Country"
              parentClassName="is-flex-1"
              disabled={isLocked}
              value={address.country}
              onChange={(e) =>
                onDetailsChange(
                  {
                    ...details,
                    address: {
                      ...address,
                      country: e.currentTarget.value,
                      state: "",
                    },
                  },
                  posIdx
                )
              }
            >
              <option value="" />
              {Object.entries(countriesByCode).map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          </div>
          <Textarea
            id="emergency-contact"
            label="Emergency Contact(s) (Name, Relation, Phone)"
            parentClassName={parentClassName}
            disabled={isLocked}
            value={details.emergencyContact}
            onChange={(e) =>
              onDetailsChange(
                { ...details, emergencyContact: e.currentTarget.value },
                posIdx
              )
            }
          />
        </>
      )}

      {tourYear.requiresPassport && (
        <>
          <PassportNotice
            concludedAt={concludedAt}
            isLocked={isLocked}
            tourYear={tourYear}
          />

          <div className="is-flex is-column-gap-1">
            <Input
              disabled={isLocked}
              id={`${idPrefix}-passport-number`}
              label="Passport Number"
              onChange={(e) =>
                onDetailsChange(
                  { ...details, passportNumber: e.currentTarget.value },
                  posIdx
                )
              }
              parentClassName="is-flex-1"
              value={details.passportNumber ?? ""}
            />
            <Select
              disabled={isLocked}
              id={`${idPrefix}-passport-country`}
              label="Citizenship"
              onChange={(e) =>
                onDetailsChange(
                  { ...details, passportCountry: e.currentTarget.value },
                  posIdx
                )
              }
              parentClassName="is-flex-1"
              value={details.passportCountry}
            >
              <option value="" />
              {Object.entries(countriesByCode).map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          </div>
          {(tourYear.crossesBorder || tourYear.includesFlights) && (
            <div className="is-flex is-flex-direction-column-mobile is-column-gap-1">
              <div className="margin-top-2 is-flex-1-desktop">
                <label>
                  <em>Date of Birth:</em>
                </label>
                <Datepicker
                  initialValue={details.passportBornAt}
                  isDisabled={isLocked}
                  onChange={(date) =>
                    onDetailsChange(
                      { ...details, passportBornAt: date },
                      posIdx
                    )
                  }
                />
              </div>
              <div className="margin-top-2 is-flex-1-desktop">
                <label>
                  <em>Passport Expiration:</em>
                </label>
                <Datepicker
                  initialValue={details.passportExpiredAt}
                  isDisabled={isLocked}
                  isInvalid={mustRenewPassport}
                  onChange={(date) =>
                    onDetailsChange(
                      { ...details, passportExpiredAt: date },
                      posIdx
                    )
                  }
                />
                {mustRenewPassport && (
                  <PassportExpires
                    className="margin-top-2"
                    mustExpireAfter={passportMustExpAfter}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export { GuestDetails };

const initGuestDetailsAndSameAs = (
  guests: GuestDO[],
  account: AccountDO,
  tourYear: TourYearDO
) => {
  const { primaryPosIdx, primaryGuest, isPrimaryTheOwner, primaryAddress } =
    primaryGuestInfo(guests, account);

  const guestDetails: GuestDetails[] = [];
  const sameAs: boolean[] = [];

  guests.forEach((guest, posIdx) => {
    const isOwner = posIdx === primaryPosIdx && isPrimaryTheOwner;
    const address = guest.address || primaryAddress;
    const contact = guest.emergencyContact ?? primaryGuest.emergencyContact;

    // When we detect that a guest is the account owner, we pre-populate their
    // phone, email, and address. These fields aren't stored in the guest table yet.
    // However, they will be as soon as Save and Continue button is clicked.
    const details: GuestDetails = {
      phoneNumber: guest.phoneNumber ?? (isOwner ? account.phoneNumber : ""),
      email: guest.email ?? (isOwner ? account.email : ""),
      address: {
        line1: address?.line1 ?? "",
        line2: address?.line2 ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        country: address?.country ?? "",
        zip: address?.zip ?? "",
      },
      emergencyContact: contact ?? "",
      age: guest.age,
    };

    if (tourYear.requiresPassport) {
      Object.assign(details, {
        passportNumber: guest.passportNumber,
        passportCountry: guest.passportCountry ?? "US",
      });
      if (tourYear.crossesBorder || tourYear.includesFlights) {
        Object.assign(details, {
          passportBornAt: guest.passportBornAt,
          passportExpiredAt: guest.passportExpiredAt,
        });
      }
    }

    guestDetails.push(details);

    if (guest === primaryGuest) {
      sameAs.push(false); // N/A even though technically true
    } else {
      sameAs.push(
        address?.id === primaryAddress?.id &&
          contact === primaryGuest.emergencyContact
      );
    }
  });

  return { guestDetails, sameAs };
};

const resetAddress = <T extends object>(obj: T) =>
  Object.keys(obj).reduce((acc, key) => {
    // @ts-expect-error Key warning.
    acc[key] = key === "country" ? "US" : "";
    return acc;
  }, {} as T);
