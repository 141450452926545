// Borrowed from https://github.com/sean-beard/react-hash-link/blob/b3824bf6d24f9cc0152084c8879d724daeff84ac/src/components/HashLinkObserver.tsx
// Another approach is react-router-hash-link which is not adapted to v6 yet: rafgraph/react-router-hash-link#92

import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const LocationObserver: FC = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { hash, pathname } = location;

  useEffect(() => {
    if (!hash) {
      // When navigating to another page, restore the scroll position.
      if (prevLocation?.pathname !== pathname) {
        window.scrollTo(0, 0);
      }
      return;
    }

    const elementId = hash.slice(1);
    const element = document.getElementById(elementId);
    if (element) {
      scrollInto(element);
      return;
    }

    /* eslint-disable-next-line prefer-const */
    let observerTimeout: number;

    // If there is a hash ID but no element, re-check after each DOM mutation
    const loadingObserver = new MutationObserver(
      (_: MutationRecord[], observer: MutationObserver) => {
        const missingElement = document.getElementById(elementId);
        if (missingElement) {
          scrollInto(missingElement);
          observer.disconnect();
          window.clearTimeout(observerTimeout);
        }
      }
    );
    loadingObserver.observe(document, { childList: true, subtree: true });

    // Disconnect the observer after 5s
    observerTimeout = window.setTimeout(
      () => loadingObserver.disconnect(),
      5_000
    );

    return () => {
      loadingObserver.disconnect();
      window.clearTimeout(observerTimeout);
    };
  }, [hash, pathname]);

  // useEffect(() => {
  //   if (prevLocation?.hash === hash) {

  //   }

  // }, [hash, pathname]);

  return null;
};

export { LocationObserver };

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function scrollInto(element: HTMLElement) {
  const header = document.querySelector("#app header") as HTMLElement;
  const y =
    Math.round(element.getBoundingClientRect().top) + // rounding closes the small gap b/w the header and the step
    window.scrollY -
    header.offsetHeight;
  window.scrollTo(0, y);
}
