import {
  weekdayNames,
  weekdayAbbreviations,
  monthNames,
  monthAbbreviations,
  dayFromDate,
  yyyy_MM_dd,
} from "data-model";
import { useModal, SVG } from "react-components";
import {
  FC,
  useState,
  useRef,
  KeyboardEvent,
  useEffect,
  MouseEvent,
} from "react";
import clsx from "clsx";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";

type Props = JSX.IntrinsicElements["input"] &
  Omit<DatepickerModalProps, "defaultDate" | "onClose"> & {
    date: string;
    hasError?: (date: string) => boolean;
    hasIcon?: boolean;
    id: string;
    label?: string;
    parentClassName?: string;
  };

const Datepicker: FC<Props> = ({
  date,
  hasError,
  hasIcon = false,
  id,
  label = "Date",
  parentClassName,
  // Datepicker props
  onDateSelect,
  dateStart,
  dateEnd,
  highlightStart,
  highlightEnd,
  validStart,
  validEnd,
  errorInvalid,
  errorConfirm,
  confirmButton,
  shouldConfirm,
  footnote,
  // Rest of input props
  disabled,
  ...rest
}) => {
  const setModal = useModal();

  const closeModal = () => setModal({ isOpen: false, body: null });

  const openModal = () =>
    !disabled &&
    setModal({
      isOpen: true,
      body: (
        <DatepickerModal
          defaultDate={date}
          onDateSelect={onDateSelect}
          onClose={closeModal}
          dateStart={dateStart}
          dateEnd={dateEnd}
          highlightStart={highlightStart}
          highlightEnd={highlightEnd}
          validStart={validStart}
          validEnd={validEnd}
          errorInvalid={errorInvalid}
          errorConfirm={errorConfirm}
          confirmButton={confirmButton}
          shouldConfirm={shouldConfirm}
          footnote={footnote}
        />
      ),
    });

  return (
    <div className={parentClassName}>
      <div className="has-floating-label">
        <input
          {...rest}
          type="text"
          readOnly
          id={id}
          data-cy={id}
          className={clsx(
            "input is-clickable",
            hasError?.(date) && `has-border-color-red`,
            rest.className
          )}
          value={DateTime.fromISO(date).toFormat("MMM dd/yyyy")}
          onClick={openModal}
          onKeyDown={(e) => e.key === "Enter" && openModal()}
          disabled={disabled}
          placeholder={label}
        />
        <label htmlFor={id}>{label}</label>
        {hasIcon && (
          <span data-cy={`${id}-calendar-icon`} className="field-helper">
            <SVG
              className="is-clickable"
              path="site/icon/calendar"
              alt="Calendar"
              onClick={openModal}
              height={16}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export { Datepicker, Props as DatepickerProps };

///
/// Modal
///

interface DatepickerModalProps {
  defaultDate?: string; // YYYY-MM-DD
  onDateSelect: (date: string) => void;
  onClose: () => void;
  dateStart?: string; // YYYY-MM-DD
  dateEnd?: string; // YYYY-MM-DD
  highlightStart?: string; // YYYY-MM-DD
  highlightEnd?: string; // YYYY-MM-DD
  validStart?: string; // YYYY-MM-DD
  validEnd?: string; // YYYY-MM-DD
  errorInvalid?: string; // error when currDate falls out of [validStart, validEnd] range
  errorConfirm?: (date: string) => string | undefined; // error when shouldConfirm(currDate) returns true
  confirmButton?: (date: string) => string | undefined; // button label when shouldConfirm(currDate) returns true
  shouldConfirm?: (date: string) => boolean;
  footnote?: string;
}

const defaultConfirm = () => false;

const DatepickerModal: FC<DatepickerModalProps> = ({
  defaultDate,
  onDateSelect,
  onClose,
  dateStart = "1970-01-01",
  dateEnd = "9999-12-31",
  highlightStart,
  highlightEnd,
  validStart,
  validEnd,
  errorInvalid = "Choose a different date",
  errorConfirm,
  confirmButton,
  shouldConfirm = defaultConfirm,
  footnote,
}) => {
  let date;
  try {
    date = DateTime.fromFormat(defaultDate!, yyyy_MM_dd);
  } catch {
    date = DateTime.now(); // fallback to today
  }

  const [currDate, setCurrDate] = useState(date.toISODate());
  const [month, setMonth] = useState(date.month - 1); // 0-11
  const [year, setYear] = useState(date.year); // YYYY

  const grid = useRef<HTMLDivElement>(null);
  const rows = calendarGrid(month, year, dateStart, dateEnd);
  const firstDateInRows = firstDate(rows) || "";
  const { rowIdx, cellIdx } = rowCellFromDate(rows, currDate);

  const leftArrow = useRef<HTMLButtonElement>(null);
  const rightArrow = useRef<HTMLButtonElement>(null);
  const hideLeftArrow = gridContains(rows, dateStart);
  const hideRightArrow = gridContains(rows, dateEnd);

  const isHighlighted = (date: string) =>
    highlightStart && highlightEnd
      ? date >= highlightStart && date <= highlightEnd
      : false;

  const isValid = (date: string) =>
    validStart && validEnd ? date >= validStart && date <= validEnd : true;

  const isRangeBound = (date: string) =>
    dateStart && dateEnd ? date >= dateStart && date <= dateEnd : true;

  const cellTextColor = (date: string) => {
    const currMonth = Number(date.slice(5, 7)) - 1; // 0-11
    if (currMonth !== month) {
      return "has-text-black";
    }
    return isValid(date) ? "has-text-light-blue" : "has-text-dark-gray";
  };

  const isCurrDateValid = isValid(currDate);
  const shouldConfirmCurrDate = shouldConfirm(currDate);
  const showFooter = Boolean(
    !isCurrDateValid || footnote || shouldConfirmCurrDate
  );

  useEffect(() => {
    for (const el of grid.current!.children) {
      const span = el as HTMLSpanElement;
      const date = span.dataset.date;
      if (date === currDate) {
        // Whenever currDate changes, focus the new cell.
        // Mouse clicks already set the focus natively,
        // so we only need to do this for the arrow keys.
        if (document.activeElement !== span) {
          span.focus();
        }
        break;
      }
    }
  }, [currDate]);

  useEffect(() => {
    // When we hide the left/right arrow, document.activeElement becomes <body />
    // So, we have to manually return the focus to the 1st tabbable element.
    if (hideLeftArrow) {
      rightArrow.current!.focus();
    } else if (hideRightArrow) {
      leftArrow.current!.focus();
    }
  }, [month]);

  const handleLeftArrow = () => {
    if (hideLeftArrow) {
      return;
    }

    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleRightArrow = () => {
    if (hideRightArrow) {
      return;
    }

    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleDateClick = (e: MouseEvent<HTMLSpanElement>) => {
    const date = e.currentTarget.dataset.date!;
    setCurrDate(date);
    if (isValid(date) && !shouldConfirm(date)) {
      onDateSelect(date);
      onClose();
    }
  };

  const handleDateConfirm = () => {
    onDateSelect(currDate);
    onClose();
  };

  const handleGridKeystrokes = (e: KeyboardEvent) => {
    let newDate;
    switch (e.key) {
      case "ArrowUp":
        if (rowIdx === 0) {
          handleLeftArrow();
        }
        newDate = DateTime.fromISO(currDate).minus({ weeks: 1 }).toISODate();
        break;
      case "ArrowRight":
        if (rowIdx === rows.length - 1 && cellIdx === 6) {
          handleRightArrow();
        }
        newDate = DateTime.fromISO(currDate).plus({ days: 1 }).toISODate();
        break;
      case "ArrowDown":
        if (rowIdx === rows.length - 1) {
          handleRightArrow();
        }
        newDate = DateTime.fromISO(currDate).plus({ weeks: 1 }).toISODate();
        break;
      case "ArrowLeft":
        if (cellIdx === 0 && rowIdx === 0) {
          handleLeftArrow();
        }
        newDate = DateTime.fromISO(currDate).minus({ days: 1 }).toISODate();
        break;
      case "Enter":
        if (isCurrDateValid && !shouldConfirmCurrDate) {
          onDateSelect(currDate);
          onClose();
        }
        break;
    }

    if (newDate && isRangeBound(newDate)) {
      setCurrDate(newDate);
    }
  };

  const handleOtherKeystrokes = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        onClose();
        break;
      case "Tab": {
        // When they go to prev/next month and then tab into the grid,
        // the first cell gets focused (it has tabIndex 0).
        // Note: this only works with a debounce (!)
        // (otherwise activeElement is still left/right arrow button).
        const focused = document.activeElement as HTMLElement | null;
        const date = focused?.dataset?.date; // if it has a data-date, it's a grid cell
        if (date && currDate && currDate !== date) {
          setCurrDate(date);
        }
        break;
      }
    }
  };

  const debounceGridKeystrokes = debounce(handleGridKeystrokes, 50);

  const debounceOtherKeystrokes = debounce(handleOtherKeystrokes, 50);

  return (
    <div className="datepicker" onKeyDown={debounceOtherKeystrokes}>
      <header className="padding-2 has-background-light-gray">
        <div className="is-flex is-align-items-center margin-bottom-1">
          <button
            ref={leftArrow}
            className={clsx("button is-ghost", hideLeftArrow && "is-invisible")}
            onClick={handleLeftArrow}
          >
            <SVG
              className="is-flipped-x"
              path="site/icon/caret-right-blue"
              alt="Left Arrow"
              height={16}
            />
          </button>
          <div className="is-flex-1 has-text-centered">
            <strong aria-label={`${monthNames[month]} ${year}`}>
              {monthAbbreviations[month]} {year}
            </strong>
          </div>
          <button
            ref={rightArrow}
            className={clsx(
              "button is-ghost",
              hideRightArrow && "is-invisible"
            )}
            onClick={handleRightArrow}
          >
            <SVG
              path="site/icon/caret-right-blue"
              alt="Right Arrow"
              height={16}
            />
          </button>
        </div>
        <div className="is-flex is-justify-content-space-between padding-x-2">
          {weekdayAbbreviations.map((weekdayAbbr, index) => (
            <div key={weekdayAbbr} aria-label={weekdayNames[index]}>
              {weekdayAbbr}
            </div>
          ))}
        </div>
      </header>
      <div className="padding-2">
        <div ref={grid} className="calendar" onKeyDown={debounceGridKeystrokes}>
          {rows.map((row, rowIndex) =>
            row.map((date, cellIndex) => {
              const key = `row-${rowIndex}-cell-${cellIndex}`;
              if (!date) {
                return <span key={key}></span>;
              }

              const isSelected = date === currDate;
              return (
                <span
                  key={key}
                  tabIndex={tabIndex(
                    currDate,
                    rowIdx,
                    cellIdx,
                    date,
                    firstDateInRows
                  )}
                  className={clsx(
                    "has-text-right is-clickable",
                    isSelected ? "padding-1 is-size-1" : "padding-2",
                    cellTextColor(date),
                    isSelected && "has-text-weight-bold",
                    isHighlighted(date) && "has-background-skyblue"
                  )}
                  data-date={date}
                  onClick={handleDateClick}
                  aria-label={DateTime.fromISO(date).toFormat("MMMM d, yyyy")}
                  role="button"
                >
                  {dayFromDate(date)}
                </span>
              );
            })
          )}
        </div>
      </div>
      {showFooter && (
        <footer className="padding-top-2 padding-x-3">
          {footnote && (
            <>
              <p
                className={clsx(
                  "is-marginless",
                  !isCurrDateValid && "has-text-red"
                )}
              >
                <strong>{footnote}</strong>
              </p>
              <hr className="divider margin-y-2" />
            </>
          )}
          {!isCurrDateValid && (
            <p className="is-marginless" aria-live="polite">
              {errorInvalid}
            </p>
          )}
          {shouldConfirmCurrDate && (
            <div aria-live="polite">
              <p className="is-marginless">
                {errorConfirm?.(currDate) || "Please confirm"}
              </p>
              <button
                data-cy="date-picker-confirm-btn"
                className="button is-yellow is-fullwidth margin-top-3"
                onClick={handleDateConfirm}
              >
                {confirmButton?.(currDate) || "OK, I Confirm"}
              </button>
            </div>
          )}
        </footer>
      )}
    </div>
  );
};

type CalendarGrid = (string | undefined)[][];

export const calendarGrid = (
  month: number, // 0-11
  year: number, // YYYY
  rangeStart = "1970-01-01",
  rangeEnd = "9999-12-31"
) => {
  const firstDate = DateTime.fromISO(`${year}-${fillZeros(month + 1)}-01`);
  const lastDate = firstDate.endOf("month");

  const rows: CalendarGrid = [[], [], [], [], []];
  let currRow = 0, // 0-4 or 0-5
    currIdx = weekdayNames.indexOf(firstDate.weekdayLong); // 0 (Sun) to 6 (Sat)

  // Fill first row to the left => prev month
  if (currIdx > 0) {
    const currDate = firstDate.minus({ days: 1 });
    const currYear = currDate.year; // YYYY
    const currMonth = currDate.month; // 1-12
    let currDay = currDate.day; // 1-31

    for (let i = currIdx - 1; i >= 0; i--) {
      rows[currRow][i] = rangeBound(
        `${currYear}-${fillZeros(currMonth)}-${fillZeros(currDay--)}`,
        rangeStart,
        rangeEnd
      );
    }
  }

  const currDate = firstDate;
  const currYear = currDate.year; // YYYY
  const currMonth = currDate.month; // 1-12
  let currDay = currDate.day; // 1-31

  while (currDay <= lastDate.day) {
    if (currIdx === 7) {
      currIdx = 0; // basically, i %= 7
      currRow++;
    }

    if (!rows[currRow]) {
      rows.push([]); // occasional 6th row
    }

    rows[currRow][currIdx] = rangeBound(
      `${currYear}-${fillZeros(currMonth)}-${fillZeros(currDay)}`,
      rangeStart,
      rangeEnd
    );

    currDay++;
    currIdx++;
  }

  // Fill last row to the right => next month
  if (currIdx < 7) {
    const currDate = lastDate.plus({ days: 1 });
    const currYear = currDate.year; // YYYY
    const currMonth = currDate.month; // 1-12
    let currDay = currDate.day; // 1-31

    for (let i = currIdx; i < 7; i++) {
      rows[currRow][i] = rangeBound(
        `${currYear}-${fillZeros(currMonth)}-${fillZeros(currDay++)}`,
        rangeStart,
        rangeEnd
      );
    }
  }

  return rows;
};

const rangeBound = (date: string, rangeStart: string, rangeEnd: string) =>
  date >= rangeStart && date <= rangeEnd ? date : undefined;

const fillZeros = (int: number) => `${String(int).padStart(2, "0")}`;

// Given a date, finds its rowIdx and cellIdx
export const rowCellFromDate = (rows: CalendarGrid, date: string) => {
  if (date === "") {
    // No date is currently selected
    return { rowIdx: -1, cellIdx: -1 };
  }

  // Unfortuantely, we can't use binary search because some cells will be
  // undefined when a range is applied https://stackoverflow.com/a/32201055
  for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
    for (let cellIdx = 0; cellIdx < rows[rowIdx].length; cellIdx++) {
      if (rows[rowIdx][cellIdx] === date) {
        return { rowIdx, cellIdx };
      }
    }
  }

  // The date is selected but the calendar currently shows a different month.
  return { rowIdx: -1, cellIdx: -1 };
};

const gridContains = (rows: CalendarGrid, date: string) => {
  const { rowIdx, cellIdx } = rowCellFromDate(rows, date);
  return rowIdx !== -1 && cellIdx !== -1;
};

const firstDate = (rows: CalendarGrid) => {
  for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
    for (let cellIdx = 0; cellIdx < rows[rowIdx].length; cellIdx++) {
      const cell = rows[rowIdx][cellIdx];
      if (!cell) {
        continue;
      }
      return cell;
    }
  }
};

const tabIndex = (
  currDate: string,
  currDateRowIdx: number,
  currDateCellIdx: number,
  otherDate: string,
  firstDateInRows: string
) => {
  if (currDateRowIdx === -1 && currDateCellIdx === -1) {
    // currDate is not displayed because a different month is being viewed
    return otherDate === firstDateInRows ? 0 : -1;
  } else {
    return currDate === otherDate ? 0 : -1;
  }
};
