import { DatepickerProps, Datepicker } from "../../../form";
import {
  busDeparts,
  TransportDraft,
  directionLowerFrom,
  TourYearDO,
  capitalize,
  pluralize,
  isEarlyArrival,
  isLateDeparture,
  isLateArrival,
  isEarlyDeparture,
  MMM_d_yyyy,
} from "data-model";
import { DateTime } from "luxon";
import { ReactNode } from "react";

const shoulderMonthStart = (date: DateTime) =>
  date.minus({ months: 1 }).startOf("month");

const shoulderMonthEnd = (date: DateTime) =>
  date.plus({ months: 1 }).endOf("month");

export const useDatepicker = ({
  departedAt,
  concludedAt,
  tourYear,
  draft,
  // datepicker props
  id,
  onDateSelect,
  disabled,
  ...rest
}: {
  departedAt: string;
  concludedAt: string;
  tourYear: TourYearDO;
  draft: TransportDraft;
} & Omit<DatepickerProps, "date">): [string, ReactNode] => {
  const { isArrival } = draft;
  const direction = directionLowerFrom(isArrival);

  const tourStarts = departedAt;
  const tourStartDate = DateTime.fromISO(tourStarts);
  const tourEnds = concludedAt;
  const tourEndDate = DateTime.fromISO(tourEnds);
  const tourSecondDay = tourStartDate.plus({ days: 1 });
  const busLeaves = busDeparts(tourYear.singleNightStay, tourStarts);

  const { singleNightStay } = tourYear;
  const date = draft.scheduledDate || (isArrival ? tourStarts : tourEnds);

  const dateStart = shoulderMonthStart(
    isArrival ? tourStartDate : tourEndDate
  ).toISODate();
  const dateEnd = shoulderMonthEnd(
    isArrival ? tourStartDate : tourEndDate
  ).toISODate();
  const validStart = isArrival ? dateStart : tourSecondDay.toISODate();
  const validEnd = isArrival ? busLeaves : dateEnd;

  const datepicker = (
    <Datepicker
      {...rest}
      id={id}
      label={`${capitalize(direction)} Date`}
      date={date}
      onDateSelect={onDateSelect}
      dateStart={dateStart}
      dateEnd={dateEnd}
      disabled={disabled}
      highlightStart={tourStarts}
      highlightEnd={tourEnds}
      validStart={validStart}
      validEnd={validEnd}
      errorInvalid={
        isArrival
          ? `Sorry, we're not able to accomodate your selection for this tour. Please choose an ${direction} date on or before ${DateTime.fromISO(
              busLeaves
            ).toFormat(MMM_d_yyyy)}`
          : `Please choose ${direction} on or after ${tourEndDate.toFormat(
              MMM_d_yyyy
            )}`
      }
      errorConfirm={(date) => {
        if (isArrival) {
          if (isEarlyArrival(date, tourStarts)) {
            return "You will arrive early before the start date of your tour. Is this correct?";
          } else if (isLateArrival(date, singleNightStay, tourStarts)) {
            return "You will arrive 1 day late after the start date of your tour. You will miss the first day. Is this correct?";
          }
        } else {
          if (isEarlyDeparture(date, tourStarts, tourEnds)) {
            const daysDiff = DateTime.fromISO(tourEnds).diff(
              DateTime.fromISO(date),
              "days"
            ).days;
            return `You will depart ${daysDiff} day${pluralize(
              daysDiff
            )} early before the end date of your tour. ${
              daysDiff === 1 ? "You will miss the last day. " : ""
            }Is this correct?`;
          } else if (isLateDeparture(date, tourEnds)) {
            return "You will depart late after the end date of your tour. Is this correct?";
          }
        }
      }}
      shouldConfirm={(date) =>
        isArrival
          ? isEarlyArrival(date, tourStarts) ||
            isLateArrival(date, singleNightStay, tourStarts)
          : isEarlyDeparture(date, tourStarts, tourEnds) ||
            isLateDeparture(date, tourEnds)
      }
      confirmButton={(date) => {
        if (isArrival) {
          if (isEarlyArrival(date, tourStarts)) {
            return "Yes, I'm arriving early";
          } else if (isLateArrival(date, singleNightStay, tourStarts)) {
            return "Yes, I'm arriving 1 day late";
          }
        } else {
          if (isEarlyDeparture(date, tourStarts, tourEnds)) {
            const daysDiff = DateTime.fromISO(tourEnds).diff(
              DateTime.fromISO(date),
              "days"
            ).days;
            return `Yes, I'm departing ${daysDiff} day${pluralize(
              daysDiff
            )} early`;
          } else if (isLateDeparture(date, tourEnds)) {
            return "Yes, I'm departing late";
          }
        }
      }}
      footnote={
        isArrival
          ? `Tour starts ${tourStartDate.toFormat(MMM_d_yyyy)}`
          : `Tour ends ${tourEndDate.toFormat(MMM_d_yyyy)}`
      }
    />
  );

  return [date, datepicker];
};
