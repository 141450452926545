import { FlightSearchProps } from "./FlightSearch";
import { useDatepicker } from "./shared";
import {
  isEarlyArrival,
  isLateArrival,
  isEarlyDeparture,
  isLateDeparture,
} from "data-model";
import { FC } from "react";

const OtherPlans: FC<FlightSearchProps> = ({
  departure,
  draft,
  positionalIdx,
  onDraftChange,
  tourYear,
  idPrefix,
}) => {
  const { departedAt, concludedAt } = departure;
  const [date, datepicker] = useDatepicker({
    departedAt,
    concludedAt,
    tourYear,
    draft,
    id: `${idPrefix}-datepicker`,
    onDateSelect: (scheduledDate) =>
      onDraftChange(
        {
          stayAtCaravanHotel: undefined,
          extraNight: undefined,
          stayNote: "",
          scheduledDate,
        },
        positionalIdx,
        draft.isArrival
      ),
  });

  let message;
  if (draft.isArrival) {
    if (isEarlyArrival(date, departedAt)) {
      message = (
        <>
          You will arrive early <strong>before</strong> the start date of your
          tour.
        </>
      );
    } else if (isLateArrival(date, tourYear.singleNightStay, departedAt)) {
      message = (
        <>
          You will arrive 1 day <strong>after</strong> the start date of your
          tour.
        </>
      );
    }
  } else {
    if (isEarlyDeparture(date, departedAt, concludedAt)) {
      message = (
        <>
          You will depart early <strong>before</strong> the end date of your
          tour.
        </>
      );
    } else if (isLateDeparture(date, concludedAt)) {
      message = (
        <>
          You will depart late <strong>after</strong> the end date of your tour.
        </>
      );
    }
  }

  return (
    <form className="columns is-centered">
      <div className="column is-5">{datepicker}</div>
      <p className="column is-7 is-marginless is-line-height-normal">
        <small className="is-size-7">
          <em>{message}</em>
        </small>
      </p>
    </form>
  );
};

export { OtherPlans };
