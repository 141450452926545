import { MMM_d_yyyy, PaymentDO } from "data-model";
import { FC } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props {
  className?: string;
  title: string;
  total: number;
  payment: PaymentDO;
}

const PaidFee: FC<Props> = ({ className, title, total, payment }) => (
  <p
    className={clsx(
      "payment-row is-marginless is-flex is-justify-content-space-between is-align-items-center has-text-green is-line-height-small",
      className
    )}
  >
    <span className="payment-item">{title}</span>

    <em>
      {DateTime.fromISO(payment.submittedAt).toFormat(MMM_d_yyyy)} (...
      {payment.cardLastFour})
    </em>

    <em className="payment-amount has-text-right">{total / 100}</em>
  </p>
);

export { PaidFee };

const WaivedFee: FC<Pick<Props, "className" | "title">> = ({
  className,
  title,
}) => (
  <p
    className={clsx(
      "payment-row is-marginless is-flex is-justify-content-space-between is-align-items-center has-text-green is-line-height-small",
      className
    )}
  >
    <span className="payment-item">{title}</span>
    <span className="payment-amount has-text-right">0</span>
  </p>
);

export { WaivedFee };
