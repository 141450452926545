import { chicagoTime } from "./TimelinePlayground";
import { db, publicTourIds } from "../jsongo";
import { Container } from "../elements";
import { departuresBySeries } from "../api";
import {
  seriesDOsForTourYear,
  arrOfSize,
  finalPaymentTotal,
  FeeDO_Type,
  GuestDO,
  BookingDO,
  daysUntilDepartureEOD,
  roomTypeFrom,
  capitalize,
  roomTypeCapitalized,
  totalGuestsFrom,
  MAX_GUESTS,
  MAX_ROOMS,
  calcRefundAmount,
  depositFee,
  tppFee,
  finalPaymentFee,
  CancellationFeeDO_Type,
  TourYearDO,
  cancellationCosts,
  TransactionStatus,
  ConsumptionDO,
  parseSeriesId,
  posIdxFrom,
  RefundLineItemDO,
  PaymentLineItemDO,
} from "data-model";
import { FC, useState, Fragment, useEffect } from "react";
import { klona } from "klona/json";
import { DateTime } from "luxon";

const CancellationPlayground: FC = () => {
  const [tour, setTour] = useState("guatemala");

  const currentYear = new Date().getFullYear() + 1;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: currentYear,
  }) as TourYearDO;
  const series = seriesDOsForTourYear(db, tour, currentYear)[0];
  const priceName = "Super Value";

  const nowDate = DateTime.now();
  const now = nowDate.toISODate();
  const [departures, setDepartures] = useState<string[]>([]);
  const [departedAt, setDepartedAt] = useState(now);

  const [cancelledDate, setCancelledDate] = useState(now);
  const [cancelledTime, setCancelledTime] = useState(nowDate.toFormat("HH:mm"));
  const cancellationDate = DateTime.fromISO(
    `${cancelledDate}T${cancelledTime}`
  );
  const cancellationCT = chicagoTime(cancellationDate);

  const [roomGuests, setRoomGuests] = useState([2, 1]);
  const totalGuests = totalGuestsFrom(roomGuests);

  const [paidFees, setPaidFees] = useState(
    arrOfSize(totalGuests).map(() => [false, false, false])
  );

  useEffect(() => {
    const [code, year] = parseSeriesId(series);
    departuresBySeries(code, +year).then((deps) => {
      setDepartures(deps);
      setDepartedAt(middleItem(deps));
    });
  }, [series._id]);

  const handlePaidFeesChange = (
    posIdx: number,
    feeIdx: number,
    isPaid: boolean
  ) => {
    const newPaidFees = klona(paidFees);
    newPaidFees[posIdx][feeIdx] = isPaid;
    // TPP/Final interlock
    if (isPaid) {
      if (feeIdx === 1 || feeIdx === 2) {
        newPaidFees[posIdx][0] = true;
      }
    } else {
      if (feeIdx === 0) {
        newPaidFees[posIdx][1] = newPaidFees[posIdx][2] = false;
      }
    }

    setPaidFees(newPaidFees);
  };

  const refundLineItems: RefundLineItemDO[] = [];
  const consumptions: ConsumptionDO[] = [];
  const transactionStatus: TransactionStatus = "capturedPendingSettlement";

  const booking = {
    guests: roomGuests.reduce((acc, guestsInRoom, roomIdx) => {
      arrOfSize(guestsInRoom).forEach((_, guestIdx) => {
        const final = finalPaymentTotal(tourYear, priceName, guestsInRoom);
        const posIdx = posIdxFrom(roomGuests, roomIdx, guestIdx);
        const guest = {
          fees: [
            {
              type: FeeDO_Type.DEPOSIT,
              amount: 30000,
              total: 30000,
              paymentLineItems: paidFees[posIdx][0]
                ? [
                    {
                      payment: { id: 1, transactionStatus },
                    } as PaymentLineItemDO,
                  ]
                : [],
              refundLineItems,
              consumptions,
            },
            {
              type: FeeDO_Type.TPP,
              amount: 12900,
              total: 12900,
              paymentLineItems: paidFees[posIdx][1]
                ? [
                    {
                      payment: { id: 1, transactionStatus },
                    } as PaymentLineItemDO,
                  ]
                : [],
              refundLineItems,
              consumptions,
            },
            {
              type: FeeDO_Type.FINAL_PAYMENT,
              amount: final.amount,
              tax: final.tax,
              total: final.amount + final.tax,
              paymentLineItems: paidFees[posIdx][2]
                ? [
                    {
                      payment: { id: 1, transactionStatus },
                    } as PaymentLineItemDO,
                  ]
                : [],
              refundLineItems,
              consumptions,
            },
          ],
        } as GuestDO;
        acc.push(guest);
      });
      return acc;
    }, [] as GuestDO[]),
    roomGuests,
    departedAt,
  } as BookingDO;

  const [guestsToCancel, setGuestsToCancel] = useState(
    booking.guests.map(() => false)
  );

  const syncRoomGuests = (roomGuests: number[]) => {
    const totalGuests = totalGuestsFrom(roomGuests);
    const arr = arrOfSize(totalGuests);
    setPaidFees(arr.map(() => [false, false, false]));
    setGuestsToCancel(arr.map(() => false));
  };

  const results = cancellationCosts(
    guestsToCancel,
    booking,
    tourYear,
    priceName,
    cancellationDate
  );

  return (
    <Container>
      <p className="is-flex margin-top-1">
        <label htmlFor="tour" className="margin-right-1">
          <strong>Tour:</strong>
        </label>
        <select
          id="tour"
          value={tour}
          onChange={(e) => setTour(e.currentTarget.value)}
        >
          {publicTourIds.map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </select>
      </p>

      <p className="is-flex">
        <label htmlFor="date">
          <strong>Departure date:</strong>
        </label>
        <select
          className="margin-x-1"
          id="date"
          value={departedAt}
          onChange={(e) => setDepartedAt(e.currentTarget.value)}
        >
          {departures.map((departure) => (
            <option key={departure} value={departure}>
              {departure}
            </option>
          ))}
        </select>
        ({priceName})
      </p>

      <p>
        <strong>Cancelling:</strong>
        <input
          type="date"
          max={departedAt}
          value={cancelledDate}
          onChange={(e) => setCancelledDate(e.currentTarget.value)}
          style={{ maxWidth: 140 }}
          className="margin-x-1"
        />
        <input
          type="time"
          value={cancelledTime}
          onChange={(e) => setCancelledTime(e.currentTarget.value)}
          style={{ maxWidth: 110 }}
        />
        {cancellationCT && (
          <>
            <br />
            {cancellationCT}
          </>
        )}
      </p>

      <p>
        <strong className="margin-right-1">Days before start of tour:</strong>
        {daysUntilDepartureEOD(departedAt, cancellationDate)}
      </p>

      <p>
        <strong>Rooms:</strong>
        {roomGuests.map((guestsInRoom, roomIdx) => (
          <select
            key={roomIdx}
            value={roomTypeFrom(guestsInRoom)}
            className="margin-left-1"
            onChange={(e) => {
              const roomType = e.currentTarget.value;
              const newRoomGuests = [...roomGuests];
              newRoomGuests[roomIdx] = guestsInRoomFrom(roomType);
              setRoomGuests(newRoomGuests);
              syncRoomGuests(newRoomGuests);
            }}
          >
            {["quad", "triple", "double", "single"].map((roomType) => (
              <option
                key={roomType}
                value={roomType}
                disabled={!canChangeRoomTo(roomGuests, roomIdx, roomType)}
              >
                {capitalize(roomType)}
              </option>
            ))}
          </select>
        ))}
      </p>

      <p>
        <button
          className="margin-right-1"
          disabled={
            totalGuests === MAX_GUESTS || roomGuests.length === MAX_ROOMS
          }
          onClick={() => {
            const newRoomGuests = [...roomGuests, totalGuests === 5 ? 1 : 2];
            setRoomGuests(newRoomGuests);
            syncRoomGuests(newRoomGuests);
          }}
        >
          Add Room
        </button>
        <button
          disabled={roomGuests.length === 1}
          onClick={() => {
            const newRoomGuests = roomGuests.slice(0, -1);
            setRoomGuests(newRoomGuests);
            syncRoomGuests(newRoomGuests);
          }}
        >
          Remove Room
        </button>
      </p>

      <div
        className="is-grid is-row-gap-1 margin-bottom-3"
        style={{ gridTemplateColumns: "1fr 1fr 1fr 1.7fr auto" }}
      >
        <span></span>
        <strong>Deposit</strong>
        <strong className="has-text-centered-mobile">TPP</strong>
        <strong className="has-text-centered-mobile">Final</strong>
        <strong>Cancel</strong>
        {roomGuests.map((guestsInRoom, roomIdx) => (
          <Fragment key={roomIdx}>
            <strong
              style={{ gridColumn: "1/-1" }}
              className="has-background-light-gray"
            >
              Room {roomIdx + 1} ({roomTypeCapitalized(guestsInRoom)})
            </strong>
            {arrOfSize(guestsInRoom).map((_, guestIdx) => {
              const posIdx = posIdxFrom(roomGuests, roomIdx, guestIdx);
              const guest = booking.guests[posIdx];
              const deposit = depositFee(guest);
              const tpp = tppFee(guest);
              const final = finalPaymentFee(guest);

              return (
                <Fragment key={posIdx}>
                  <span>Guest {posIdx + 1}</span>
                  <div>
                    <input
                      type="checkbox"
                      className="margin-right-1"
                      checked={paidFees[posIdx][0]}
                      onChange={(e) =>
                        handlePaidFeesChange(posIdx, 0, e.currentTarget.checked)
                      }
                    />
                    {deposit.total / 100}
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="margin-right-1"
                      checked={paidFees[posIdx][1]}
                      onChange={(e) =>
                        handlePaidFeesChange(posIdx, 1, e.currentTarget.checked)
                      }
                    />
                    {tpp.total / 100}
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="margin-right-1"
                      checked={paidFees[posIdx][2]}
                      onChange={(e) =>
                        handlePaidFeesChange(posIdx, 2, e.currentTarget.checked)
                      }
                    />
                    {final.amount / 100} + {final.tax / 100}
                  </div>
                  <input
                    type="checkbox"
                    checked={guestsToCancel[posIdx]}
                    onChange={(e) => {
                      const newGuestsToCancel = [...guestsToCancel];
                      newGuestsToCancel[posIdx] = e.currentTarget.checked;
                      setGuestsToCancel(newGuestsToCancel);
                    }}
                  />
                </Fragment>
              );
            })}
          </Fragment>
        ))}
      </div>

      <hr className="margin-y-3" />

      {results.map((room, roomIdx) => (
        <Fragment key={roomIdx}>
          <p className="margin-y-1 has-background-light-gray">
            <strong>
              Room {roomIdx + 1} ({roomTypeCapitalized(room.length)})
            </strong>
          </p>
          {room.map((res, guestIdx) => (
            <p key={guestIdx} className="is-marginless is-flex">
              <span className="margin-right-2">
                Guest {posIdxFrom(roomGuests, roomIdx, guestIdx) + 1}{" "}
              </span>
              <span className="is-flex-1 is-flex-column">
                {!res ? (
                  "No Change"
                ) : res.adjustmentFee ? (
                  <span className="is-flex">
                    <span className="is-flex-1">
                      {adjustmentLabel(res.adjustmentFee.type)}
                    </span>{" "}
                    +${(res.adjustmentFee.amount + res.adjustmentFee.tax) / 100}
                  </span>
                ) : (
                  <>
                    {res.cancellationFees.map((cFee, idx) => (
                      <span key={idx} className="is-flex">
                        <span className="is-flex-1">
                          {cancelFeeLabels[cFee.type]}
                        </span>{" "}
                        -${cFee.amount / 100}
                      </span>
                    ))}
                    <span className="is-flex">
                      <span className="is-flex-1">Refund</span> $
                      {calcRefundAmount(
                        booking.guests[
                          posIdxFrom(roomGuests, roomIdx, guestIdx)
                        ],
                        res.cancellationFees
                      ) / 100}
                    </span>
                  </>
                )}
              </span>
            </p>
          ))}
        </Fragment>
      ))}
    </Container>
  );
};

export { CancellationPlayground };

const cancelFeeLabels = {
  [CancellationFeeDO_Type.DEPOSIT_FEE]: "$300 Fee",
  [CancellationFeeDO_Type.HALF_TOUR_PRICE]: "50% of Tour Price",
  [CancellationFeeDO_Type.TOUR_PRICE]: "100% of Tour Price",
  [CancellationFeeDO_Type.PREMIUM]: "Non-Refundable Premium",
  [CancellationFeeDO_Type.DEDUCTIBLE]: "Deductible",
  [CancellationFeeDO_Type.ADMIN_FEE]: "Admin Fee",
};

const adjustmentLabel = (feeType: FeeDO_Type) => {
  switch (feeType) {
    case FeeDO_Type.CHANGE_QUAD_TO_TPL:
      return "Changing Room Type to Triple";
    case FeeDO_Type.CHANGE_QUAD_TO_DBL:
    case FeeDO_Type.CHANGE_TPL_TO_DBL:
      return "Changing Room Type to Double";
    case FeeDO_Type.CHANGE_QUAD_TO_SGL:
    case FeeDO_Type.CHANGE_TPL_TO_SGL:
    case FeeDO_Type.CHANGE_DBL_TO_SGL:
      return "Changing Room Type to Single";
  }
  throw new Error(`Unhandled ${feeType}`);
};

export const middleItem = <T,>(arr: T[]) => arr[Math.floor(arr.length / 2)];

// Inverse of roomTypeFrom
const guestsInRoomFrom = (roomType: string) =>
  ["single", "double", "triple", "quad"].indexOf(roomType) + 1;

const canChangeRoomTo = (
  roomGuests: number[],
  roomIdx: number,
  roomType: string
) => {
  const newRoomGuests = [...roomGuests];
  newRoomGuests[roomIdx] = guestsInRoomFrom(roomType);
  return totalGuestsFrom(newRoomGuests) <= MAX_GUESTS;
};
