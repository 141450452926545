import { DottedHeading, SVG } from "react-components";
import { FC } from "react";
import clsx from "clsx";

interface Props {
  hasUnpaidFees: boolean;
  isLocked: boolean;
  onCheckout: () => void;
  total: number;
  totalFormatted: string;
}

const Checkout: FC<Props> = ({
  hasUnpaidFees,
  isLocked,
  onCheckout,
  total,
  totalFormatted,
}) => (
  <>
    <DottedHeading
      className={clsx(
        "is-size-1 margin-top-3 margin-bottom-1 margin-right-2",
        !hasUnpaidFees && "has-text-green"
      )}
      borderClassName={clsx(
        "has-border-bottom-width-large",
        isLocked && "has-border-bottom-color-mid-gray",
        !hasUnpaidFees && "has-border-bottom-color-green"
      )}
      left={
        hasUnpaidFees ? (
          "Total to Pay Now"
        ) : (
          <span className="is-flex is-align-items-baseline">
            <SVG
              className="margin-right-1 is-align-self-flex-start"
              path={"/site/icon/checkmark-circled-green"}
              alt="Green Checkmark"
              height={22}
            />
            Total Paid
          </span>
        )
      }
      right={total === 0 && hasUnpaidFees ? "$" : totalFormatted}
    />

    <p className="is-marginless is-line-height-normal">
      <small className="is-size-7">
        All payments in U.S. Dollars. Travel Protection optional.
      </small>
    </p>

    {hasUnpaidFees && (
      <button
        className="button is-yellow is-fullwidth margin-top-2"
        onClick={onCheckout}
        disabled={total === 0}
      >
        Pay Now with Credit Card
      </button>
    )}
  </>
);

export { Checkout };
