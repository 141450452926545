import bookingFaqs from "../../../../../markdown/booking-faqs.md";
import { ExistingBooking, ExistingBookingProps } from "../utils";
import { Container } from "../elements";
import { AnemicNavigation } from "../components";
import { textFromHTML } from "react-components";
import { FC } from "react";
import { Link } from "react-router-dom";

const [, titleHtml, contentHtml] = bookingFaqs.split(/(<h1>.*?<\/h1>)/);

const BookingFAQs = () => <ExistingBooking component={FAQs} />;

const FAQs: FC<ExistingBookingProps> = ({ booking }) => (
  <>
    <Container>
      <h1 className="is-marginless is-flex is-align-items-center">
        <span className="is-flex-1">{textFromHTML(titleHtml)}</span>
        <Link className="is-size-3" to={`/bookings/${booking.number}`}>
          Back to Booking
        </Link>
      </h1>
      <hr className="divider is-red" />

      <div dangerouslySetInnerHTML={{ __html: contentHtml }} />

      <AnemicNavigation bookingNumber={booking.number} />
    </Container>
  </>
);

export { BookingFAQs };
