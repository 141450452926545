import { FC } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  title: string;
}

const UnavailableFee: FC<Props> = ({ className, title }) => (
  <p
    className={clsx(
      "payment-row is-marginless is-flex is-justify-content-space-between is-align-items-center is-line-height-small",
      className
    )}
  >
    <span className="payment-item">{title}</span>

    <em>Unavailable</em>

    <span className="payment-amount" />
  </p>
);

export { UnavailableFee };
