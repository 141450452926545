import { useDatepicker } from "./shared";
import { FlightEditorProps } from "./FlightEditor";
import { searchFlights } from "../../../api";
import {
  airlineCarriers,
  bestFlightIdx,
  directionLowerFrom,
  FlightInfo,
  flightDefaults,
  h_mm_a,
  TransportDraft,
  flightMissedDueToTime,
} from "data-model";
import {
  Input,
  Select,
  useModal,
  SVG,
  useErrors,
  ErrorMessage,
  Tooltip,
  FlightPickerModal,
} from "react-components";
import { FC, useRef, useState, useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";

const airlinesByIata = airlineCarriers.reduce((acc, carrier) => {
  acc[carrier.iata] = carrier;
  return acc;
}, {} as Record<string, typeof airlineCarriers[number] | undefined>);

interface Props extends FlightEditorProps {
  idPrefix: string;
}

const FlightSearch: FC<Props> = ({
  departure,
  draft,
  idPrefix,
  isUnlocked,
  onDraftChange,
  positionalIdx,
  tourYear,
  transport,
}) => {
  const setModal = useModal();
  const isMounted = useRef(false);

  const { isArrival } = draft;
  const isLocked = !isUnlocked;

  const direction = directionLowerFrom(isArrival);
  const transferInfo =
    departure.transfers?.[direction] ?? tourYear.transfers[direction];

  ///
  /// Form config
  ///

  const flightForm = useRef<HTMLFormElement>(null);
  // Careful: when the airline picker is a select, the flight number input
  // is at index 2, and when it's an input with a cross button, it's at 3.
  const flightInput = flightForm.current?.[flightForm.current?.length - 1] as
    | HTMLInputElement
    | undefined;

  const [isLoading, setLoading] = useState(false);

  const [, datepicker] = useDatepicker({
    departedAt: departure.departedAt,
    concludedAt: departure.concludedAt,
    tourYear,
    draft,
    id: `${idPrefix}-datepicker`,
    onDateSelect: (scheduledDate) => {
      if (scheduledDate === draft.scheduledDate) return;
      // Clear the previous flight error, if any
      flightInput?.setCustomValidity("");
      // Changing the date will clear the previous flight result and the notes.
      onDraftChange(
        {
          ...flightDefaults,
          stayAtCaravanHotel: undefined,
          extraNight:
            scheduledDate === transport?.scheduledDate
              ? transport.extraNight
              : undefined,
          stayNote: "",
          scheduledDate,
        },
        positionalIdx,
        isArrival
      );
      setErrors([]);
    },
    disabled: isLocked,
  });

  const isCustomAirline = draft.airline
    ? !airlineCarriers.some((c) => c.iata === draft.airline)
    : false;
  const [enterAirline, setEnterAirline] = useState(isCustomAirline);

  const airlineIata = draft.airline || "";

  ///
  /// Flight search
  ///

  const [errors, catchErrors, setErrors] = useErrors();
  const flightError = flightMissedDueToTime(
    draft,
    departure.concludedAt,
    transferInfo
  );

  const findFlights = async (draft: TransportDraft, signal: AbortSignal) => {
    if (
      !draft.scheduledDate ||
      !draft.airline ||
      !draft.flightNumber ||
      !flightForm.current?.reportValidity()
    )
      return;

    setLoading(true);

    let flights: FlightInfo[] = [];

    await catchErrors(
      async () => {
        flights = await searchFlights(
          {
            airlineIata: draft.airline!,
            date: draft.scheduledDate,
            number: draft.flightNumber!,
            isArrival: `${draft.isArrival}`,
          },
          { signal }
        );
      },
      () => {
        setLoading(false);

        // Don't allow saving if the flight was not found
        if (flights.length === 0) {
          if (!signal.aborted) {
            const flightInput = flightForm.current?.[
              flightForm.current?.length - 1
            ] as HTMLInputElement | undefined;
            flightInput?.setCustomValidity("Invalid flight");
          }
          return;
        }

        const bestIdx = bestFlightIdx(flights, isArrival, transferInfo);

        const updateFlightInfo = (flightIdx: number) => {
          const flightInfo = flights[flightIdx];
          onDraftChange(
            {
              fromAirport: flightInfo.from,
              fromAirportName: flightInfo.fromName,
              toAirport: flightInfo.to,
              toAirportName: flightInfo.toName,
              scheduledTime: flightInfo.time,
            },
            positionalIdx,
            draft.isArrival
          );
        };

        updateFlightInfo(bestIdx);

        if (flights.length > 1) {
          setModal({
            isOpen: true,
            body: (
              <FlightPickerModal
                idPrefix={idPrefix}
                isArrival={draft.isArrival}
                flights={flights}
                currentIdx={bestIdx}
                onIdxChange={updateFlightInfo}
                onClose={() => setModal({ isOpen: false, body: null })}
              />
            ),
          });
        }
      }
    );
  };

  // Why useCallback? https://stackoverflow.com/a/61786423
  // Why not debounce(searchFlights)? https://github.com/lodash/lodash/issues/4400
  const debounceFindFlight = useCallback(debounce(findFlights, 1000), []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const controller = new AbortController();
    debounceFindFlight(draft, controller.signal);

    return () => controller.abort();
  }, [draft.scheduledDate, draft.airline, draft.flightNumber]);

  return (
    <>
      <form ref={flightForm} className="columns">
        <div className="column is-5">{datepicker}</div>
        <div className="column">
          {enterAirline ? (
            <Input
              invalid={
                (flightForm.current?.[1] as HTMLInputElement)?.validity
                  ?.patternMismatch
              }
              id={`${idPrefix}-airline-custom`}
              label="Airline"
              pattern="[A-Z0-9]{2}"
              maxLength={2}
              disabled={isLocked}
              value={airlineIata}
              onChange={(e) => {
                flightInput?.setCustomValidity("");
                // Changing the airline or flight number will clear the previous flight, but keep the notes.
                onDraftChange(
                  {
                    ...flightDefaults,
                    airline: e.currentTarget.value.toUpperCase(),
                  },
                  positionalIdx,
                  isArrival
                );
                setErrors([]);
              }}
              childrenRight={
                <button
                  type="button"
                  className="button is-ghost is-link is-paddingless field-helper"
                  disabled={isLocked}
                  onClick={() => {
                    flightInput?.setCustomValidity("");
                    onDraftChange(
                      { ...flightDefaults, airline: "" },
                      positionalIdx,
                      isArrival
                    );
                    setEnterAirline(false);
                    setErrors([]);
                  }}
                >
                  <SVG path="site/icon/close-blue" alt="Cross" height={16} />
                </button>
              }
            />
          ) : (
            <Select
              id={`${idPrefix}-airline`}
              label={airlinesByIata[airlineIata]?.name || "Airline"}
              parentClassName="airline-picker"
              disabled={isLocked}
              value={airlineIata}
              onChange={(e) => {
                flightInput?.setCustomValidity("");
                const { value } = e.currentTarget;
                const isCustom = value === "Custom";
                onDraftChange(
                  { ...flightDefaults, airline: isCustom ? "" : value },
                  positionalIdx,
                  isArrival
                );
                setErrors([]);
                if (isCustom) {
                  setEnterAirline(true);
                }
              }}
            >
              <option value="" />
              <option value="Custom">Enter an Airline</option>
              {airlineCarriers.map(({ iata, name }) => (
                <option key={iata} value={iata}>
                  {iata} - {name}
                </option>
              ))}
            </Select>
          )}
        </div>
        <div className="column">
          <Input
            invalid={
              flightInput?.validity?.patternMismatch ||
              flightInput?.validity?.customError
            }
            id={`${idPrefix}-flight-number`}
            label="Flight"
            pattern="[0-9]{1,4}"
            maxLength={4}
            disabled={isLocked}
            value={draft.flightNumber || ""}
            onChange={(e) => {
              flightInput?.setCustomValidity("");
              onDraftChange(
                { ...flightDefaults, flightNumber: e.currentTarget.value },
                positionalIdx,
                isArrival
              );
              setErrors([]);
            }}
            childrenRight={
              isLoading && (
                <SVG
                  className="field-helper"
                  path="site/icon/spinner"
                  alt="Spinner"
                  height={19}
                />
              )
            }
          />
        </div>
      </form>
      {draft.fromAirportName && draft.toAirportName && draft.scheduledTime && (
        <p className="margin-y-2 is-flex is-align-items-center is-justify-content-space-between-mobile is-line-height-small">
          <span className="padding-1 is-rounded-small">
            From
            <Tooltip
              title={draft.fromAirportName}
              parentClassName="margin-left-1"
            >
              <strong className="has-text-light-blue">
                {draft.fromAirport}
              </strong>
            </Tooltip>
          </span>
          <SVG
            className="margin-x-2-desktop"
            path="site/icon/plane"
            alt="Airplane"
            height={19}
          />
          <span className="padding-1 is-rounded-small">
            To
            <Tooltip
              title={draft.toAirportName}
              parentClassName="margin-left-1"
              titleClassName="is-centered-x"
            >
              <strong className="has-text-light-blue">{draft.toAirport}</strong>
            </Tooltip>
          </span>
          <span className="padding-1 margin-left-2-desktop is-rounded-small">
            {isArrival ? "Arrives" : "Departs"}{" "}
            {DateTime.fromISO(draft.scheduledTime).toFormat(h_mm_a)}
          </span>
        </p>
      )}
      <ErrorMessage
        errors={
          // Whether we have any search related errors
          errors.length
            ? errors
            : flightError // Whether the flight will be missed
            ? [{ message: flightError }]
            : []
        }
        className="margin-top-2 margin-bottom-0"
      />
    </>
  );
};

export { FlightSearch, Props as FlightSearchProps };
