import { Checkbox, DottedHeading } from "react-components";
import { FeeDO_Type } from "data-model";
import { ReactNode, FC } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  title: ReactNode;
  total: number;
  isAdded: boolean;
  onChange: (
    positionalIdx: number,
    feeType: FeeDO_Type,
    isAdded: boolean
  ) => void;
  positionalIdx: number;
  feeType: FeeDO_Type;
  isLocked: boolean;
}

const UnpaidFee: FC<Props> = ({
  className,
  title,
  total,
  isAdded,
  onChange,
  positionalIdx,
  feeType,
  isLocked,
}) => (
  <div className={clsx("payment-row is-flex is-align-items-center", className)}>
    <DottedHeading
      heading="span"
      className="is-flex-1 margin-right-2"
      left={title}
      right={isAdded ? undefined : total / 100}
    />
    <Checkbox
      id={`${feeType}-${positionalIdx}`}
      checked={isAdded}
      onChange={() => onChange(positionalIdx, feeType, !isAdded)}
      disabled={isLocked}
    />
  </div>
);

export { UnpaidFee };
