import { Input, InputProps } from "react-components";
import { FC, useState } from "react";
import clsx from "clsx";

const Password: FC<InputProps> = (props) => {
  const [inputType, setInputType] = useState<"password" | "text">("password");

  return (
    <Input
      label="Password"
      minLength={4}
      maxLength={255}
      {...props}
      type={inputType}
      className={clsx("padding-right-11", props.className)}
      childrenRight={
        <button
          type="button"
          className="button is-ghost is-link is-paddingless field-helper"
          onClick={() => {
            setInputType((prevType) =>
              prevType === "password" ? "text" : "password"
            );
          }}
        >
          {inputType === "password" ? "Show" : "Hide"}
        </button>
      }
    />
  );
};

export { Password };
