import { sendCode } from "../api";
import { ErrorMessage, SVG, useErrors } from "react-components";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";

interface Props {
  phoneNumber: string;
  onReenter: () => void;
  onVerify: (code: string) => Promise<void>;
}

const SmsChallenge: FC<Props> = ({ phoneNumber, onReenter, onVerify }) => {
  const [digits, setDigits] = useState(["", "", "", ""]);

  const second = useRef<HTMLInputElement>(null);
  const third = useRef<HTMLInputElement>(null);
  const fourth = useRef<HTMLInputElement>(null);

  const [errors, catchErrors] = useErrors();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentCodeLength = digits.filter(Boolean).length;

    if (currentCodeLength === 4) {
      setLoading(true);
      catchErrors(
        () => onVerify(digits.join("")),
        () => setLoading(false)
      );
    }
  }, [digits]);

  const handleDigitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, dataset } = e.currentTarget;
    const index = +(dataset as { index: string }).index;
    const isDigit = /\b\d\b/.test(value);

    // !value is when they pressed Backspace or Del (i.e. empty input)
    if (isDigit || !value) {
      setDigits(updateDigitAtIdx(digits, index, value));
    }

    if (isDigit) {
      const nextInput =
        index === 0
          ? second
          : index === 1
          ? third
          : index === 2
          ? fourth
          : null;

      nextInput?.current?.focus();
    }
  };

  const handleCodeResend = () => {
    setLoading(true);

    catchErrors(
      () => sendCode({ phoneNumber }),
      () => setLoading(false)
    );
  };

  return (
    <>
      <h1 id="modal-title" className="is-size-2">
        SMS Challenge
      </h1>
      <div id="modal-description">
        <p>
          Enter the code we sent SMS over to: <br /> {phoneNumber}
        </p>

        <div className="is-flex is-align-items-center">
          <input
            autoFocus
            className="input is-square margin-right-1 has-text-centered is-rounded input-challenge"
            value={digits[0] ?? ""}
            data-index={0}
            onChange={handleDigitChange}
            aria-label="Challenge Code First Digit"
            pattern="[0-9]"
            maxLength={1}
            disabled={loading}
          />
          <input
            ref={second}
            className="input is-square margin-x-1 has-text-centered is-rounded input-challenge"
            value={digits[1] ?? ""}
            data-index={1}
            onChange={handleDigitChange}
            aria-label="Challenge Code Second Digit"
            pattern="[0-9]"
            maxLength={1}
            disabled={loading}
          />
          <input
            ref={third}
            className="input is-square margin-x-1 has-text-centered is-rounded input-challenge"
            value={digits[2] ?? ""}
            data-index={2}
            onChange={handleDigitChange}
            aria-label="Challenge Code Third Digit"
            pattern="[0-9]"
            maxLength={1}
            disabled={loading}
          />
          <input
            ref={fourth}
            className="input is-square margin-left-1 has-text-centered is-rounded input-challenge"
            value={digits[3] ?? ""}
            data-index={3}
            onChange={handleDigitChange}
            aria-label="Challenge Code Fourth Digit"
            pattern="[0-9]"
            maxLength={1}
            disabled={loading}
          />
          <SVG
            path="/site/icon/spinner"
            alt="Spinner"
            className={clsx("margin-left-2", !loading && "is-invisible")}
            height={30}
          />
        </div>

        <ErrorMessage errors={errors} />

        <p className="margin-top-4">
          Didn&apos;t get the code?{" "}
          <button
            className="button is-ghost is-link is-paddingless is-inline-flex"
            onClick={handleCodeResend}
            disabled={loading}
          >
            Send again
          </button>
        </p>

        <p className="margin-bottom-0">
          Wrong phone number?{" "}
          <button
            className="button is-ghost is-link is-paddingless is-inline-flex"
            onClick={onReenter}
            disabled={loading}
          >
            Re-enter number
          </button>
        </p>
      </div>
    </>
  );
};

export { SmsChallenge };

const updateDigitAtIdx = (
  code: string[],
  codeIndex: number,
  codeValue: string
) =>
  code.map((currentCodeNumber, index) =>
    codeIndex === index ? codeValue : currentCodeNumber
  );
