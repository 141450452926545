import { BookingStepper } from "./BookingStepper";
import { Documents } from "./Documents";
import { TourSurvey } from "./TourSurvey";
import { ExistingBooking, ExistingBookingProps } from "../../utils";
import { Accordion } from "../../elements";
import { BookingCard, AnemicNavigation, ExtendHold } from "../../components";
import { db } from "../../jsongo";
import {
  isBookingAnnulled,
  isHoldOrBookingActive,
  TourYearDO,
  yearFromDate,
} from "data-model";
import { PriceDetails } from "react-components";
import { FC } from "react";

const context = "active-booking";

const ManageBooking = () => <ExistingBooking component={ActiveBooking} />;

const ActiveBooking: FC<ExistingBookingProps> = ({ account, booking }) => {
  const { tour, departedAt, number } = booking;

  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: yearFromDate(departedAt),
  }) as TourYearDO;
  const isActive = isHoldOrBookingActive(booking, tourYear.timezoneEnd);

  return (
    <div className="container is-flex-1-mobile is-fullwidth padding-y-2 padding-x-0">
      <div className="padding-x-2-mobile">
        <h1 className="is-marginless" data-cy={`${context}-heading`}>
          Manage Booking
        </h1>
        <hr className="divider is-red margin-bottom-2" />

        <BookingCard
          booking={booking}
          className="margin-bottom-2"
          withImages
          hideInvite={!isActive}
          status={<ExtendHold booking={booking} />}
        />
      </div>

      {isActive && <BookingStepper booking={booking} account={account} />}

      <div className="padding-x-2-mobile">
        <Documents booking={booking} />

        {isActive && (
          <Accordion
            title="Price Details"
            className="margin-top-2 margin-bottom-3"
          >
            <article className="padding-2 padding-x-4-desktop">
              <PriceDetails
                lastColClassName="is-size-7-mobile"
                lastColSpanClassName="is-block-desktop has-text-nowrap-desktop"
                priceName={booking.departure.priceName}
                roomGuests={booking.roomGuests}
                tourYear={tourYear}
              />

              <ul className="padding-left-4 margin-top-2 margin-bottom-0">
                <li>Airfare is extra and not sold by Caravan</li>
              </ul>
            </article>
          </Accordion>
        )}

        {!isBookingAnnulled(booking) && (
          <TourSurvey account={account} booking={booking} />
        )}

        {isActive && <AnemicNavigation bookingNumber={number} />}
      </div>
    </div>
  );
};

export { ManageBooking };
