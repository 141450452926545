import { AccountContext, ACCOUNT_KEY } from "../context";
import { db } from "../jsongo";
import { deleteHold } from "../api";
import { Container } from "../elements";
import {
  BookingCard,
  ClientService,
  ExtendHold,
  MinimalNavigation,
} from "../components";
import {
  BookingDO,
  isBookingCancelled,
  isHoldDeleted,
  isHoldExpired,
  isBookingActive,
  isBookingTravelled,
  isHoldActive,
  sortActive,
  sortCancelled,
  sortExpiredOrDeleted,
  sortTravelled,
  yearFromDate,
  TourYearDO,
} from "data-model";
import { useModal, SVG, ExternalLink } from "react-components";
import { FC, useContext } from "react";
import { useLocation, Navigate, Link } from "react-router-dom";
import { set } from "idb-keyval";
import clsx from "clsx";

const context = "my-account";

interface Props {
  cancelled?: boolean;
  travelled?: boolean;
  expired?: boolean;
}

const Bookings: FC<Props> = ({ cancelled, travelled, expired }) => {
  const { search } = useLocation();
  const isHolding = search === "?holding";
  const isReplaced = search === "?replaced";

  const setModal = useModal();

  const [account, setAccount] = useContext(AccountContext);
  if (!account) throw new Error("Unauthenticated");
  const { bookings } = account;

  const active = !cancelled && !travelled && !expired;
  const filteredBookings = bookings.filter((booking) => {
    const { departedAt, tour } = booking;

    if (cancelled) return isBookingCancelled(booking);
    if (expired) return isHoldExpired(booking) || isHoldDeleted(booking);

    const tourYear = db.tourYear.findByIdOrFail({
      tour_id: tour,
      year: yearFromDate(departedAt),
    }) as TourYearDO;

    if (travelled) return isBookingTravelled(booking, tourYear.timezoneEnd);

    return isBookingActive(booking, tourYear.timezoneEnd);
  });

  // Bookings are already sorted by departedAt in ascending order.
  // Rearrange them by event date in reverse chronological order (newest/earliest first).
  if (cancelled) {
    filteredBookings.sort(sortCancelled);
  } else if (travelled) {
    filteredBookings.sort(sortTravelled);
  } else if (expired) {
    filteredBookings.sort(sortExpiredOrDeleted);
  }

  const activeHolds = bookings.filter(isHoldActive).sort(sortActive);

  if ((isHolding || isReplaced) && active && !activeHolds.length) {
    return <Navigate to="/bookings" />;
  }

  const handleModalClose = () => setModal({ isOpen: false, body: null });

  const handleHoldDelete = (activeHold: BookingDO) => {
    setModal({
      isOpen: true,
      body: (
        <DeleteHoldModal
          booking={activeHold}
          onDelete={async () => {
            const { deletedAt } = await deleteHold(activeHold.id);

            const updatedAccount = {
              ...account,
              bookings: account.bookings.map((b) =>
                b.id === activeHold.id ? { ...b, deletedAt } : b
              ),
            };

            await set(ACCOUNT_KEY, updatedAccount);

            setAccount(updatedAccount);

            handleModalClose();
          }}
          onClose={handleModalClose}
        />
      ),
    });
  };

  return (
    <>
      {(isHolding || isReplaced) && active && !!activeHolds.length && (
        <aside className="padding-2 has-background-light-gray has-border-bottom-gray">
          <div className="container is-paddingless is-flex">
            <SVG
              path="site/icon/checkmark-circled-green"
              alt="Green Checkmark"
              height={29}
            />
            <div className="is-flex-1 margin-left-2 is-line-height-normal has-text-green">
              <h2 className="is-marginless is-size-1">
                {isHolding ? "You Are Holding Space!" : "Hold Replaced"}
              </h2>
              <p className="is-marginless">
                Confirmation sent to {account.email}
              </p>
            </div>
          </div>
        </aside>
      )}
      <Container>
        <h1
          className="is-marginless is-flex is-align-items-center"
          data-cy={`${context}-heading`}
        >
          <span className="is-flex-1">
            {active
              ? "Active Bookings"
              : cancelled
              ? "Cancelled Bookings"
              : travelled
              ? "Travelled Bookings"
              : "Expired & Deleted Holds"}
          </span>
          <Link to="/account" className="is-size-3">
            My Account
          </Link>
        </h1>
        <hr className="divider is-red margin-bottom-3" />

        {active &&
          activeHolds.map((hold) => (
            <BookingCard
              key={hold.id}
              booking={hold}
              className="margin-bottom-3"
              footer={
                <footer className="margin-top-3">
                  <div className="is-flex">
                    <button
                      className="button is-dark-gray margin-right-1"
                      onClick={() => handleHoldDelete(hold)}
                    >
                      Delete Hold
                    </button>
                    <Link
                      to={`/bookings/${hold.number}`}
                      className="button is-yellow is-flex-1"
                    >
                      Manage Hold
                    </Link>
                  </div>
                  <p className="is-marginless margin-top-2 is-line-height-normal">
                    <em>
                      This hold will automatically expire if a timely deposit is
                      not paid.
                    </em>
                  </p>
                </footer>
              }
              status={<ExtendHold booking={hold} />}
            />
          ))}

        {active && activeHolds.length && !filteredBookings.length ? null : (
          <>
            {filteredBookings.length ? (
              filteredBookings.map((booking) => (
                <BookingCard
                  key={booking.id}
                  booking={booking}
                  className={clsx(
                    "margin-bottom-3",
                    (cancelled || expired) && "has-text-mid-gray"
                  )}
                  hideInvite={!active}
                  footer={
                    <Link
                      to={`/bookings/${booking.number}`}
                      className={`button ${
                        active ? "is-yellow" : "is-light-blue is-outlined"
                      } margin-top-2`}
                    >
                      {active ? "Manage Booking" : "View Booking"}
                    </Link>
                  }
                />
              ))
            ) : (
              <article className="padding-3 has-border-gray is-rounded-small">
                <p className="is-marginless">
                  You currently have no{" "}
                  {active
                    ? "active"
                    : cancelled
                    ? "cancelled"
                    : travelled
                    ? "travelled"
                    : "expired or deleted"}{" "}
                  {expired ? "holds" : "bookings"} in this account.{" "}
                  {(active || travelled) && (
                    <>
                      It&apos;s easy to get started: Simply visit a Caravan tour
                      page and tap a calendar date to{" "}
                      <ExternalLink
                        className="is-undecorated-link"
                        href={`${process.env.WWW_ORIGIN}/easy-booking`}
                      >
                        <strong>Check Availability</strong>
                      </ExternalLink>
                      . Then, hold space and manage your booking online. Or,
                      choose to do it all by phone: <ClientService />.
                    </>
                  )}
                </p>

                {(active || travelled) && (
                  <p className="margin-top-2 margin-bottom-0">
                    We look forward to welcoming you on a Caravan tour soon!
                  </p>
                )}
              </article>
            )}
          </>
        )}

        <hr className="divider margin-y-3" />

        <MinimalNavigation />
      </Container>
    </>
  );
};

export { Bookings };

interface DeleteHoldModalProps {
  booking: BookingDO;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteHoldModal: FC<DeleteHoldModalProps> = ({
  booking,
  onDelete,
  onClose,
}) => (
  <>
    <h1 id="modal-title" className="has-text-centered margin-bottom-4">
      Delete Hold?
    </h1>

    <BookingCard
      booking={booking}
      className="margin-bottom-2"
      numberIsAdjacent
      hideInvite
      hideTourEnd
      hideStatus
    />

    <footer className="is-flex">
      <button
        className="button is-dark-gray is-flex-1 margin-right-1"
        onClick={onClose}
      >
        No
      </button>

      <button className="button is-yellow is-flex-1" onClick={onDelete}>
        Yes, Delete
      </button>
    </footer>
  </>
);
