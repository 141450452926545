import { SVG } from "react-components";
import { PropsWithChildren, ReactNode, FC, useState } from "react";
import clsx from "clsx";

interface Props extends PropsWithChildren {
  className?: string;
  title: ReactNode;
}

const Accordion: FC<Props> = ({ className, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={clsx("has-border-gray is-rounded-small", className)}>
      <div
        className={clsx(
          isOpen && "has-background-light-gray has-border-bottom-gray"
        )}
      >
        <button
          className="button is-ghost padding-y-2-pt-5 is-fullwidth is-rounded-small is-line-height-small"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="is-flex-1 has-text-left has-text-blue">{title}</span>
          <SVG
            path={`/site/icon/${
              isOpen ? "caret-up-heavy" : "caret-down-heavy"
            }`}
            alt={`Caret ${isOpen ? "Up" : "Down"}`}
            width={24}
          />
        </button>
      </div>
      {isOpen && children}
    </section>
  );
};

export { Accordion };
