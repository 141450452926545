import { useModal, ModalValue } from "react-components";
import { lowerCaseMeridiem, nowInCT } from "data-model";
import { PropsWithChildren, FC } from "react";

interface Props extends PropsWithChildren {
  showNumber?: boolean;
}

const ClientService: FC<Props> = ({ showNumber = false, children }) => {
  const setModal = useModal();

  return (
    <button
      type="button"
      className="button is-ghost is-link is-paddingless is-inline-flex"
      data-cy="client-service-link"
      onClick={() => setModal(clientServiceModal)}
    >
      {children ||
        `Call Client Service${showNumber ? " at 1-800-CARAVAN" : ""}`}
    </button>
  );
};

const ClientServiceBody = () => (
  <>
    <h1 className="is-size-2">Caravan Client Service</h1>

    <p className="is-marginless">
      Mon-Fri 8am to 6pm, Sat 8am to 2pm, Central Time USA
    </p>
    <span className="is-italic">
      Central Time USA is now:{" "}
      {lowerCaseMeridiem(nowInCT().toFormat("cccc h:mma"))}
    </span>

    <p>
      USA and Canada:{" "}
      <a
        className="button is-inline-flex is-ghost is-link is-uppercase is-paddingless"
        href="tel:1-800-CARAVAN"
      >
        Call 1-800-CARAVAN
      </a>
    </p>

    <p className="margin-bottom-1">
      Wordwide:{" "}
      <a
        className="button is-inline-flex is-ghost is-link is-uppercase is-paddingless"
        href="tel:+13123219800"
      >
        Call +1-312-321-9800
      </a>
    </p>
  </>
);

export const clientServiceModal: ModalValue = {
  isOpen: true,
  body: <ClientServiceBody />,
};

export { ClientService };
