import { ACCOUNT_KEY } from "./context";
import {
  BookingCancelBookingReq,
  BookingCancelBookingRes,
  BookingChangePasswordReq,
  BookingChangePasswordRes,
  BookingCheckPwdTokenParams,
  BookingCheckPwdTokenRes,
  BookingDeleteRes,
  BookingEditAccountReq,
  BookingEditAccountRes,
  BookingEditGuestsReq,
  BookingEditGuestsRes,
  BookingEditNamesReq,
  BookingEditNamesRes,
  BookingEditTransportsReq,
  BookingEditTransportsRes,
  BookingEmailReconfirmationReq,
  BookingEmailReconfirmationRes,
  BookingExtendRes,
  BookingGetFormTokenReq,
  BookingGetFormTokenRes,
  BookingHoldSpaceReq,
  BookingHoldSpaceRes,
  BookingInviteReq,
  BookingInviteRes,
  BookingLogInRes,
  BookingLogOutRes,
  BookingRecoverPasswordRes,
  BookingResetEmailReq,
  BookingResetEmailRes,
  BookingResetPasswordReq,
  BookingResetPasswordRes,
  BookingSavePaymentReq,
  BookingSavePaymentRes,
  BookingSendCodeReq,
  BookingSendCodeRes,
  BookingSignUpReq,
  BookingSignUpRes,
  BookingSyncAccountRes,
  FlightInfo,
  FlightQuery,
  JsongoDeparturesBySeriesRes,
  apiHelpers,
} from "data-model";
import { clear, get } from "idb-keyval";

const API_URL = `https://${process.env.API_HOSTNAME}/v1/booking`;

const { json, jsonQuery, jsonPost, jsonPatch, jsonDelete } = apiHelpers(
  API_URL,
  async (err) => {
    if (err.status === 401 || err.status === 403) {
      const account = await get(ACCOUNT_KEY);
      if (err.status === 401 && account) {
        // Local cache is stale and cookie is either stale or missing
        await clear();
        setTimeout(() => location.reload());
      } else if (err.status === 403 && !account) {
        // Cookie is valid but the cache is empty
        await logOut();
      }
    }
    throw err;
  }
);

export const logIn = (
  email: string,
  password: string
): Promise<BookingLogInRes> => jsonPost("/accounts/login", { email, password });

export const recoverPassword = (
  email: string
): Promise<BookingRecoverPasswordRes> =>
  jsonPost("/password/recover", { email });

export const resetPassword = (
  payload: BookingResetPasswordReq
): Promise<BookingResetPasswordRes> => jsonPost("/password/reset", payload);

export const checkPwdToken = (
  params: BookingCheckPwdTokenParams
): Promise<BookingCheckPwdTokenRes> => jsonQuery("/password/check", params);

export const resetEmail = (
  payload: BookingResetEmailReq
): Promise<BookingResetEmailRes> => jsonPost("/email/reset", payload);

export const logOut = (): Promise<BookingLogOutRes> =>
  jsonPost("/accounts/logout", {});

export const signUp = (payload: BookingSignUpReq): Promise<BookingSignUpRes> =>
  jsonPost("/accounts/signup", payload);

export const sendCode = (
  payload: BookingSendCodeReq
): Promise<BookingSendCodeRes> => jsonPost("/challenge/send", payload);

export const holdSpace = (
  payload: BookingHoldSpaceReq
): Promise<BookingHoldSpaceRes> => jsonPost("/bookings", payload);

export const syncAccount = (): Promise<BookingSyncAccountRes> =>
  json("/accounts/me");

export const editAccount = (
  payload: BookingEditAccountReq
): Promise<BookingEditAccountRes> => jsonPatch("/accounts/me", payload);

export const changePassword = (
  payload: BookingChangePasswordReq
): Promise<BookingChangePasswordRes> =>
  jsonPatch("/accounts/me/password", payload);

export const editGuestNames = (
  bookingId: number,
  payload: BookingEditNamesReq
): Promise<BookingEditNamesRes> =>
  jsonPatch(`/bookings/${bookingId}/guests/names`, payload);

export const editGuestDetails = (
  bookingId: number,
  payload: BookingEditGuestsReq
): Promise<BookingEditGuestsRes> =>
  jsonPatch(`/bookings/${bookingId}/guests/details`, payload);

export const getFormToken = (
  bookingId: number,
  payload: BookingGetFormTokenReq
): Promise<BookingGetFormTokenRes> =>
  jsonPost(`/bookings/${bookingId}/payments/token`, payload);

export const savePayment = (
  bookingId: number,
  payload: BookingSavePaymentReq
): Promise<BookingSavePaymentRes> =>
  jsonPost(`/bookings/${bookingId}/payments`, payload);

export const searchFlights = (
  params: FlightQuery,
  init?: RequestInit
): Promise<FlightInfo[]> => jsonQuery("/flights", params, init);

export const editTransports = (
  bookingId: number,
  payload: BookingEditTransportsReq
): Promise<BookingEditTransportsRes> =>
  jsonPatch(`/bookings/${bookingId}/guests/transports`, payload);

export const deleteHold = (bookingId: number): Promise<BookingDeleteRes> =>
  jsonDelete(`/bookings/${bookingId}`);

export const inviteAvailability = (
  params: BookingInviteReq
): Promise<BookingInviteRes> =>
  jsonQuery("/bookings/availability/invite", params);

export const cancelBooking = (
  bookingId: number,
  payload: BookingCancelBookingReq
): Promise<BookingCancelBookingRes> =>
  jsonPost(`/bookings/${bookingId}/cancel`, payload);

export const departuresBySeries = (
  series: string,
  year: number
): Promise<JsongoDeparturesBySeriesRes> =>
  json(`/departures/${series}/${year}`);

export const emailReconfirmation = (
  bookingId: number,
  payload: BookingEmailReconfirmationReq
): Promise<BookingEmailReconfirmationRes> =>
  jsonPost(`/bookings/${bookingId}/reconfirm`, payload);

export const extendHold = (bookingId: number): Promise<BookingExtendRes> =>
  jsonPost(`/bookings/${bookingId}/extend`, {});
