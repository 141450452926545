import { addPaymentTo } from "./DepositPayment";
import { CommonProps, Box, BoxHeading } from "./shared";
import {
  PaidFee,
  UnpaidFee,
  UnavailableFee,
  PaymentStatus,
  Checkout,
  WaivedFee,
} from "../PaymentEditor";
import { getFormToken, savePayment } from "../../../api";
import { AccountContext, ACCOUNT_KEY } from "../../../context";
import {
  adjustmentFees,
  arrDelete,
  canBuyTpp,
  depositFee,
  FeeDO_Interlocks,
  FeeDO_Type,
  feeLabel,
  finalPaymentFee,
  formatCents,
  fullNameOrPlaceholder,
  guestFee,
  hasGuestFee,
  initFeesByGuest,
  isGuestAccountOwner,
  isPaidInFull,
  totalToPayNow,
  tppFee,
  mapPaymentResponse,
  isFeePaid,
  feePayment,
  grandTotalPaid,
  isFeeOwed,
  isFeeWaived,
} from "data-model";
import {
  ExternalLinkOrText,
  PaymentForm,
  SVG,
  useModal,
} from "react-components";
import { FC, Fragment, useContext, useState } from "react";
import { klona } from "klona/json";
import clsx from "clsx";
import { set } from "idb-keyval";

// const dataCy = "stepper-final-payment";

const FinalPayment: FC<CommonProps> = ({
  booking,
  account,
  onClose,
  isUnlocked,
}) => {
  const { guests, id: bookingId, departedAt } = booking;
  const isLocked = !isUnlocked;

  const setModal = useModal();
  const [, setAccount] = useContext(AccountContext);
  const [fees, setFees] = useState(initFeesByGuest(guests));

  const paidInFull = isPaidInFull(booking, false);
  const total = paidInFull
    ? grandTotalPaid(guests)
    : totalToPayNow(fees, guests);
  const totalFormatted = formatCents(total);

  const handleFeeChange = (
    posIdx: number,
    feeType: FeeDO_Type,
    isAdded: boolean
  ) => {
    const newFees = klona(fees);
    const feesForThisGuest = newFees[posIdx];
    const guest = guests[posIdx];

    if (isAdded) {
      // When final/adjustments are added, auto-add the dependent fees.
      if (FeeDO_Interlocks.includes(feeType)) {
        for (const interlockedFee of FeeDO_Interlocks) {
          if (
            hasGuestFee(guest, interlockedFee) &&
            isFeeOwed(guestFee(guest, interlockedFee)) // just in case it was paid somehow (against the policy)
          ) {
            feesForThisGuest.push(interlockedFee);
          }
        }
      } else {
        feesForThisGuest.push(feeType);
      }
    } else {
      // When final/adjustments are removed, auto-remove the dependent fees.
      if (FeeDO_Interlocks.includes(feeType)) {
        for (const interlockedFee of FeeDO_Interlocks) {
          arrDelete(feesForThisGuest, interlockedFee);
        }
      } else {
        arrDelete(feesForThisGuest, feeType);
      }
    }

    setFees(newFees);
  };

  const handleCheckout = async () => {
    const { token } = await getFormToken(bookingId, fees);

    setModal({
      isOpen: true,
      className: "is-fullwidth",
      body: (
        <PaymentForm
          token={token}
          onClose={() => setModal({ isOpen: false, className: "", body: null })}
          onSuccess={async (res) => {
            const paymentReq = mapPaymentResponse(res);

            const payment = await savePayment(bookingId, {
              ...paymentReq,
              fees,
            });

            const updatedAccount = addPaymentTo(
              account,
              bookingId,
              payment,
              fees
            );

            await set(ACCOUNT_KEY, updatedAccount);

            setAccount(updatedAccount);

            onClose();
          }}
        />
      ),
    });
  };

  return (
    <Box>
      <BoxHeading
        className="margin-bottom-1"
        showPerson={isGuestAccountOwner(guests, account, 0)}
        isUnlocked={isUnlocked}
      >
        {fullNameOrPlaceholder(guests, account, 0)}
      </BoxHeading>

      <div className="is-flex">
        <div className="is-flex-1">
          {guests.map((guest, posIdx) => {
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            const final = finalPaymentFee(guest);
            const depositPayment = feePayment(deposit);
            const tppPayment = feePayment(tpp);
            const finalPayment = feePayment(final);
            const isFirst = posIdx === 0;

            return (
              <Fragment key={guest.id}>
                {posIdx !== 0 && (
                  <BoxHeading
                    className="margin-y-1"
                    showPerson={isGuestAccountOwner(guests, account, posIdx)}
                    isUnlocked={isUnlocked}
                  >
                    {fullNameOrPlaceholder(guests, account, posIdx)}
                  </BoxHeading>
                )}

                {depositPayment ? (
                  <PaidFee
                    className={clsx(!isFirst && "margin-top-1")}
                    title="Deposit"
                    total={deposit.total}
                    payment={depositPayment}
                  />
                ) : isFeeWaived(deposit) ? (
                  <WaivedFee className="margin-top-1" title="Deposit" />
                ) : (
                  <UnpaidFee
                    className={clsx(!isFirst && "margin-top-1")}
                    title="Deposit"
                    total={deposit.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.DEPOSIT)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.DEPOSIT}
                    isLocked={isLocked}
                  />
                )}

                {tppPayment ? (
                  <PaidFee
                    className="margin-top-1"
                    title="Protection"
                    total={tpp.total}
                    payment={tppPayment}
                  />
                ) : isFeeWaived(tpp) ? (
                  <WaivedFee
                    className="margin-top-1"
                    title="Travel Protection"
                  />
                ) : canBuyTpp(guest, departedAt, false, isLocked) ? (
                  <UnpaidFee
                    className="margin-top-1"
                    title={
                      <span className="is-flex">
                        Travel Protection
                        <ExternalLinkOrText
                          isLink={isUnlocked}
                          href={`${process.env.WWW_ORIGIN}/caravan-travel-protection.pdf`}
                        >
                          <SVG
                            className="margin-left-1"
                            path={
                              isUnlocked
                                ? "/site/icon/question-circled-blue"
                                : "/site/icon/question-circled-gray"
                            }
                            alt="Info"
                            height={16}
                          />
                        </ExternalLinkOrText>
                      </span>
                    }
                    total={tpp.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.TPP)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.TPP}
                    isLocked={isLocked}
                  />
                ) : (
                  <UnavailableFee className="margin-top-1" title="Protection" />
                )}

                {finalPayment ? (
                  <PaidFee
                    className="margin-top-1"
                    title="Final"
                    total={final.total}
                    payment={finalPayment}
                  />
                ) : isFeeWaived(final) ? (
                  <WaivedFee className="margin-top-1" title="Final Payment" />
                ) : (
                  <UnpaidFee
                    className="margin-top-1"
                    title="Final Payment"
                    total={final.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.FINAL_PAYMENT)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.FINAL_PAYMENT}
                    isLocked={isLocked}
                  />
                )}

                {adjustmentFees(guest).map((adjustment) => {
                  const adjPayment = feePayment(adjustment);
                  return adjPayment ? (
                    <PaidFee
                      key={adjustment.type}
                      className="margin-top-1"
                      title={feeLabel(adjustment.type, true)}
                      total={adjustment.total}
                      payment={adjPayment}
                    />
                  ) : isFeeWaived(adjustment) ? (
                    <WaivedFee
                      className="margin-top-1"
                      title={feeLabel(adjustment.type)}
                    />
                  ) : (
                    <UnpaidFee
                      key={adjustment.type}
                      className="margin-top-1"
                      title={feeLabel(adjustment.type)}
                      total={adjustment.total}
                      isAdded={fees[posIdx].includes(adjustment.type)}
                      onChange={handleFeeChange}
                      positionalIdx={posIdx}
                      feeType={adjustment.type}
                      isLocked={isLocked}
                    />
                  );
                })}
              </Fragment>
            );
          })}
        </div>
        <aside className="payment-column margin-left-1 has-border-gray is-rounded-small padding-left-1 padding-right-2 is-flex-column is-justify-content-space-between">
          {guests.map((guest, posIdx) => {
            const isDepositAdded = fees[posIdx].includes(FeeDO_Type.DEPOSIT);
            const isTppAdded = fees[posIdx].includes(FeeDO_Type.TPP);
            const isFinalAdded = fees[posIdx].includes(
              FeeDO_Type.FINAL_PAYMENT
            );
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            const final = finalPaymentFee(guest);
            return (
              <div key={posIdx} className="is-size-2">
                <PaymentStatus
                  isFeeAdded={isDepositAdded}
                  isFeePaid={isFeePaid(deposit)}
                  isFeeWaived={isFeeWaived(deposit)}
                  total={deposit.total}
                />
                <PaymentStatus
                  className="margin-top-1"
                  isFeeAdded={isTppAdded}
                  isFeeAvailable={canBuyTpp(guest, departedAt, false, isLocked)}
                  isFeePaid={isFeePaid(tpp)}
                  isFeeWaived={isFeeWaived(tpp)}
                  total={tpp.total}
                />
                <PaymentStatus
                  className="margin-top-1"
                  isFeeAdded={isFinalAdded}
                  isFeePaid={isFeePaid(final)}
                  isFeeWaived={isFeeWaived(final)}
                  total={final.total}
                />
                {adjustmentFees(guest).map((adjustment) => (
                  <PaymentStatus
                    key={adjustment.type}
                    className="margin-top-1"
                    isFeeAdded={fees[posIdx].includes(adjustment.type)}
                    isFeePaid={isFeePaid(adjustment)}
                    isFeeWaived={isFeeWaived(adjustment)}
                    total={adjustment.total}
                  />
                ))}
              </div>
            );
          })}
        </aside>
      </div>

      <Checkout
        hasUnpaidFees={!paidInFull}
        isLocked={isLocked}
        onCheckout={handleCheckout}
        total={total}
        totalFormatted={totalFormatted}
      />
    </Box>
  );
};

export { FinalPayment };
