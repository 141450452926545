import { AccountContext, ACCOUNT_KEY } from "../context";
import { changePassword, editAccount, sendCode } from "../api";
import { Container, AuxContainer, Notification } from "../elements";
import { SmsChallenge } from "../components";
import { Password } from "../form";
import {
  ErrorMessage,
  hasError,
  Input,
  PhoneNumber,
  Select,
  useErrors,
  useModal,
} from "react-components";
import {
  BookingEditAccountReq,
  countriesByCode,
  isObjectEmpty,
  regionsByCountryCode,
  sortRegionsAlpha,
  trim,
} from "data-model";
import { FC, FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { set } from "idb-keyval";

const context = "edit-my-account";

const phoneNumberId = "phone-number";

const EditAccount: FC = () => {
  const navigate = useNavigate();

  const setModal = useModal();
  const [account, setAccount] = useContext(AccountContext);
  if (!account) throw new Error("Unauthenticated");

  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  const [phoneNumber, setPhoneNumber] = useState(account.phoneNumber);
  const [email, setEmail] = useState(account.email);
  const { address } = account;
  const [line1, setLine1] = useState(address.line1);
  const [line2, setLine2] = useState(address.line2 || "");
  const [country, setCountry] = useState(address.country);
  const [state, setState] = useState(address.state);
  const [city, setCity] = useState(address.city);
  const [zip, setZip] = useState(address.zip);

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => navigate("/account");

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    await catchErrors(
      async () => {
        let form: BookingEditAccountReq = { address: {} };
        if (firstName !== account.firstName) form.firstName = firstName;
        if (lastName !== account.lastName) form.lastName = lastName;
        if (phoneNumber !== account.phoneNumber) form.phoneNumber = phoneNumber;
        if (email !== account.email) form.email = email;
        if (line1 !== address.line1) form.address!.line1 = line1;
        // Both have values (different) OR one has value
        if (line2 !== address.line2 && (line2 || address.line2))
          form.address!.line2 = line2;
        if (country !== address.country || state !== address.state)
          form.address!.country = country; // state validation requires country
        if (state !== address.state) form.address!.state = state;
        if (city !== address.city) form.address!.city = city;
        if (zip !== address.zip) form.address!.zip = zip;
        if (isObjectEmpty(form.address!)) {
          delete form.address;
        }
        form = trim(form);

        if (isObjectEmpty(form)) return handleAccountUpdate();

        const saveChanges = async () => {
          const res = await editAccount(form);
          delete form.smsCode;

          const updatedAccount = {
            ...account,
            ...form,
            address: { ...address, ...form.address },
          };
          if (res.updatedAt) updatedAccount.updatedAt = res.updatedAt;
          if (res.address)
            updatedAccount.address.updatedAt = res.address.updatedAt;

          await set(ACCOUNT_KEY, updatedAccount);

          setAccount(updatedAccount);

          handleAccountUpdate();
        };

        if (form.phoneNumber) {
          form.onlyValidate = true;
          await editAccount(form);

          await sendCode({ phoneNumber });

          setModal({
            isOpen: true,
            body: (
              <SmsChallenge
                phoneNumber={phoneNumber}
                onReenter={() => {
                  handleModalClose();
                  setTimeout(() =>
                    document.getElementById(phoneNumberId)?.focus()
                  );
                }}
                onVerify={async (smsCode) => {
                  form.smsCode = smsCode;
                  delete form.onlyValidate;

                  await saveChanges();
                }}
              />
            ),
            className: "is-fullsize-desktop",
          });
        } else {
          await saveChanges();
        }
      },
      () => setIsLoading(false)
    );
  };

  const handleAccountUpdate = () =>
    setModal({
      isOpen: true,
      body: (
        <Notification
          title="Account Updated!"
          body="Your account details have been saved."
          onClose={handleModalClose}
        />
      ),
      className: "is-fullwidth",
    });

  const handleModalClose = () =>
    setModal({ isOpen: false, body: null, className: "" });

  return (
    <form className="is-flex-column is-flex-1" onSubmit={handleSave}>
      <Container>
        <h1 className="is-marginless is-flex">Edit Account Information</h1>
        <hr className="divider is-red margin-bottom-2" />

        <div className="columns">
          <div className="column is-6">
            <Input
              id="first-name"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </div>
          <div className="column is-6">
            <Input
              id="last-name"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </div>
        </div>
        <PhoneNumber
          id={phoneNumberId}
          value={phoneNumber}
          onChange={setPhoneNumber}
          containerClass="margin-y-1"
          invalid={hasError(errors, "phoneNumber")}
          specialLabel="Mobile Phone"
        />
        <Input
          id="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "email")}
        />
        <Input
          id="line-1"
          label="Address Line 1"
          parentClassName="margin-y-1"
          value={line1}
          onChange={(e) => setLine1(e.currentTarget.value)}
        />
        <Input
          id="line-2"
          label="Address Line 2 (Optional)"
          parentClassName="margin-y-1"
          value={line2}
          onChange={(e) => setLine2(e.currentTarget.value)}
        />
        <Select
          id="country"
          label="Country"
          parentClassName="margin-y-1"
          value={country}
          onChange={(e) => {
            setCountry(e.currentTarget.value);
            setState("");
          }}
        >
          <option disabled hidden value="" />
          {Object.entries(countriesByCode).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          id="state"
          label="State, Province or Region"
          parentClassName="margin-y-1"
          value={state}
          onChange={(e) => setState(e.currentTarget.value)}
        >
          <option disabled hidden value="" />
          {country &&
            Object.entries(regionsByCountryCode[country])
              .sort(sortRegionsAlpha)
              .map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
        </Select>
        <div className="columns">
          <div className="column is-8">
            <Input
              id="city"
              label="City"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </div>
          <div className="column is-4">
            <Input
              id="zip"
              label="Zip / Postal"
              value={zip}
              onChange={(e) => setZip(e.currentTarget.value)}
              minLength={1}
              maxLength={10}
            />
          </div>
        </div>

        <ErrorMessage errors={errors} />

        <button
          type="button"
          className="button is-ghost is-link is-paddingless margin-y-3"
          onClick={() =>
            setModal({
              isOpen: true,
              body: (
                <ChangePassword
                  onChange={async (updatedAt) => {
                    const updatedAccount = { ...account, updatedAt };

                    await set(ACCOUNT_KEY, updatedAccount);

                    setAccount(updatedAccount);

                    setModal({
                      isOpen: true,
                      body: (
                        <Notification
                          title="Password Updated!"
                          body="Your new password has been saved."
                          onClose={handleModalClose}
                        />
                      ),
                      className: "is-fullwidth",
                    });
                  }}
                />
              ),
              className: "is-fullwidth",
            })
          }
        >
          Change Password
        </button>

        <p className="margin-bottom-0">
          Documents and receipts are sent via email and are stored on your
          booking page. Notifications are sent via SMS to your phone.
        </p>
      </Container>
      <AuxContainer className="is-flex">
        <button
          type="button"
          className="button is-dark-gray margin-right-1"
          data-cy={`${context}-cancel`}
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="button is-yellow is-fullwidth is-flex-1"
          data-cy={`${context}-forward`}
          disabled={
            isLoading ||
            !firstName ||
            !lastName ||
            !phoneNumber ||
            !email ||
            !line1 ||
            !country ||
            !state ||
            !city ||
            !zip
          }
        >
          Save And Continue
        </button>
      </AuxContainer>
    </form>
  );
};

interface ChangePasswordProps {
  onChange: (updatedAt: string) => Promise<void>;
}

const ChangePassword: FC<ChangePasswordProps> = ({ onChange }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    await catchErrors(
      async () => {
        const { updatedAt } = await changePassword({
          currentPassword,
          newPassword,
        });

        onChange(updatedAt);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h1 id="modal-title" className="is-size-2">
        Change Password
      </h1>
      <form onSubmit={handleChangePassword}>
        <Password
          id="current-password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.currentTarget.value)}
          label="Enter Current Password"
          parentClassName="margin-bottom-4"
          invalid={hasError(errors, "current-password")}
        />
        <Password
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.currentTarget.value)}
          label="Enter New Password"
          parentClassName="margin-y-1"
          invalid={hasError(errors, "new-password")}
          autoComplete="new-password"
        />
        <Password
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          label="Confirm New Password"
          parentClassName="margin-y-1"
          autoComplete="new-password"
        />

        <ErrorMessage errors={errors} />

        <button
          type="submit"
          className="button is-fullwidth is-yellow margin-top-4"
          data-cy={`${context}-submit`}
          disabled={
            isLoading ||
            !currentPassword ||
            !newPassword ||
            !confirmPassword ||
            newPassword !== confirmPassword
          }
        >
          Change Password
        </button>
      </form>
    </>
  );
};

export { EditAccount };
