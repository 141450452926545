import { AccountContext } from "../context";
import { parseHoldSpaceParams } from "../utils";
import { Container } from "../elements";
import { BookingCard, ClientService, MinimalNavigation } from "../components";
import { db } from "../jsongo";
import {
  hasReachedHoldLimit,
  isHoldActive,
  BookingDO,
  HOLD_LIMIT,
  tourEnds,
  yearFromDate,
  TourYearDO,
} from "data-model";
import { SVG } from "react-components";
import { FC, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

const context = "booking-limit";

const HoldLimit: FC = () => {
  const navigate = useNavigate();

  const [account] = useContext(AccountContext);
  if (!account) throw new Error("Unauthenticated");
  const { bookings } = account;

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const holdSpaceReq = parseHoldSpaceParams(params);

  if (!holdSpaceReq || !hasReachedHoldLimit(bookings)) {
    return <Navigate to="/bookings" />;
  }

  const activeHold = bookings.find(isHoldActive)!;
  const { tour, roomGuests, date } = holdSpaceReq;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: yearFromDate(date),
  }) as TourYearDO;
  const newHold = {
    tour,
    roomGuests,
    departedAt: date,
    departure: {
      departedAt: date,
      concludedAt: tourEnds(date, tourYear),
    },
  } as unknown as BookingDO;

  const handleHoldReplace = async () => {
    params.append("h", `${activeHold.id}`);
    navigate(
      { pathname: "/", search: `?${params.toString()}` },
      { replace: true }
    );
  };

  return (
    <Container>
      <h1 className="is-marginless" data-cy={`${context}-hold-limit-heading`}>
        Sorry, {HOLD_LIMIT} Hold per Account Online
      </h1>
      <hr className="divider is-red margin-bottom-2" />

      <p className="is-marginless">
        <strong>To Hold New Space, either:</strong>
      </p>

      <ul className="margin-top-1 margin-bottom-2 padding-left-5">
        <li>Replace current hold with new hold below, or</li>
        <li>Pay deposit on current hold, or</li>
        <li>
          Call <ClientService>Client Service</ClientService> for more than 1
          hold
        </li>
      </ul>

      <article className="has-background-light-gray padding-2 has-border-gray is-rounded-small">
        <h2 className="is-marginless">Replace Current Hold with New Hold?</h2>

        <BookingCard
          className="margin-y-2 has-background-white is-relative"
          booking={activeHold}
          header={
            <h3 className="margin-top-0 margin-bottom-2 has-text-weight-normal">
              Current Hold ({activeHold.number})
            </h3>
          }
          footer={
            <SVG
              className="is-absolute is-centered-x"
              path="/site/icon/arrow-down-heavy"
              alt="Down Arrow"
              height={30}
              style={{ bottom: -30 }}
            />
          }
          titleHeading="h3"
          hideNumber
          hideInvite
        />

        <BookingCard
          className="margin-bottom-2 has-background-white"
          booking={newHold}
          header={
            <h3 className="margin-top-0 margin-bottom-2 has-text-weight-normal">
              New Hold
            </h3>
          }
          titleHeading="h3"
          hideNumber
          hideInvite
          hideStatus
        />

        <div className="is-flex">
          <button
            className="button is-dark-gray is-flex-1 margin-right-1"
            onClick={() => navigate(-1)}
          >
            No, Go Back
          </button>
          <button
            className="button is-yellow is-flex-1"
            onClick={handleHoldReplace}
          >
            Yes, Replace Hold
          </button>
        </div>
      </article>

      <MinimalNavigation />
    </Container>
  );
};

export { HoldLimit };
