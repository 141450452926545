import {
  NamesAndRooms,
  DepositPayment,
  GuestDetails,
  FlightDetails,
  FinalPayment,
} from "./steps";
import { db } from "../../jsongo";
import {
  BookingDO,
  AccountDO,
  yearFromDate,
  isMissingGuestNames,
  hasDepositsDue,
  isMissingGuestDetails,
  isMissingArrivalDeparture,
  hasFinalOrAdjDue,
  TourYearDO,
  MMM_d_yyyy,
} from "data-model";
import { SVG } from "react-components";
import { FC, useState, ReactNode, PropsWithChildren } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props {
  booking: BookingDO;
  account: AccountDO;
}

const BookingStepper: FC<Props> = ({ booking, account }) => {
  const { departedAt, tour } = booking;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: yearFromDate(departedAt),
  }) as TourYearDO;

  const todoNumber = todoStepNumber(booking, tourYear); // # of the step to work on next
  const isBookingComplete = todoNumber === -1;

  const [openNumber, setOpenNumber] = useState(-1); // # of the step that is open
  const isCollapsed = openNumber === -1;
  // const isStep2Unlocked = 2 <= todoNumber || isBookingComplete;
  const isStep2Unlocked = 2 <= todoNumber || isBookingComplete;
  const isStep3Unlocked = 3 <= todoNumber || isBookingComplete;

  const missingNames = isMissingGuestNames(booking);
  const owesDeposit = hasDepositsDue(booking);
  const owesFinalOrAdj = hasFinalOrAdjDue(booking);

  const handleClose = () => setOpenNumber(-1);

  const handleToggle = (stepNumber: number) => {
    if (openNumber !== -1 && stepNumber > openNumber) {
      const prevStep = document.getElementById(
        `step-${openNumber}`
      ) as HTMLDivElement;
      const prevStepHeight = prevStep.clientHeight;
      const prevPosY = window.scrollY;

      // Prevent a "jump" as if the page doesn't move
      setTimeout(() => {
        window.scroll(
          0,
          Math.max(0, prevPosY - prevStepHeight + prevStep.clientHeight)
        );
      });
    }
    setOpenNumber(stepNumber);
  };

  const commonProps = {
    booking: booking,
    account: account,
    onClose: handleClose,
  };

  return (
    <>
      <Step
        number={1}
        title="Names & Rooms"
        subtitle={missingNames && "Please enter all names before payment"}
        isComplete={!missingNames}
        isUnlocked //={1 <= todoNumber || isBookingComplete}
        isHighlighted={todoNumber === 1 && isCollapsed}
        isOpen={openNumber === 1}
        isNextOpen={openNumber === 2}
        onToggle={handleToggle}
      >
        <NamesAndRooms {...commonProps} isUnlocked />
      </Step>

      <Step
        number={2}
        title="Deposit Payment"
        subtitle={
          owesDeposit && (
            <span className="is-flex-column">
              {!missingNames && "Please pay all deposits to continue"}
            </span>
          )
        }
        isComplete={!owesDeposit}
        isUnlocked={isStep2Unlocked}
        isHighlighted={todoNumber === 2 && isCollapsed}
        isOpen={openNumber === 2}
        isNextOpen={openNumber === 3}
        onToggle={handleToggle}
      >
        <DepositPayment {...commonProps} isUnlocked={isStep2Unlocked} />
      </Step>

      <Step
        number={3}
        title="Guest Details"
        isComplete={!isMissingGuestDetails(booking, tourYear)}
        isUnlocked={isStep3Unlocked}
        isHighlighted={todoNumber === 3 && isCollapsed}
        isOpen={openNumber === 3}
        isNextOpen={openNumber === 4}
        onToggle={handleToggle}
      >
        <GuestDetails
          {...commonProps}
          isUnlocked={isStep3Unlocked}
          tourYear={tourYear}
        />
      </Step>

      <Step
        number={4}
        title="Flight Details"
        isComplete={!isMissingArrivalDeparture(booking)}
        isUnlocked={3 <= todoNumber || isBookingComplete}
        isHighlighted={todoNumber === 4 && isCollapsed}
        isOpen={openNumber === 4}
        isNextOpen={openNumber === 5}
        onToggle={handleToggle}
      >
        <FlightDetails
          {...commonProps}
          isUnlocked={isStep3Unlocked}
          tourYear={tourYear}
        />
      </Step>

      <Step
        number={5}
        title="Final Payment"
        subtitle={
          owesFinalOrAdj && (
            <span className="is-flex-column">
              Due {DateTime.fromISO(booking.finalDueBy).toFormat(MMM_d_yyyy)}
            </span>
          )
        }
        isComplete={!owesFinalOrAdj}
        isUnlocked={isStep3Unlocked}
        isHighlighted={todoNumber === 5 && isCollapsed}
        isLast
        isOpen={openNumber === 5}
        onToggle={handleToggle}
      >
        <FinalPayment {...commonProps} isUnlocked={isStep3Unlocked} />
      </Step>
    </>
  );
};

export { BookingStepper };

interface StepProps extends PropsWithChildren {
  number: number;
  title: string;
  subtitle?: ReactNode;
  isComplete?: boolean;
  hasErrors?: string[];
  isUnlocked?: boolean;
  isHighlighted?: boolean;
  isLast?: boolean; // when true, doesn't have a vertical line
  isOpen: boolean;
  isNextOpen?: boolean;
  onToggle: (number: number) => void;
}

const Step: FC<StepProps> = ({
  number,
  title,
  subtitle,
  isComplete = false,
  hasErrors = [],
  isUnlocked = false,
  isHighlighted = false,
  isLast = false,
  isOpen,
  isNextOpen = false,
  onToggle,
  children,
}) => {
  const isGrayed = isHighlighted || isOpen;
  if (isComplete) {
    subtitle = (
      <>
        <SVG
          className="margin-right-1"
          path="/site/icon/checkmark-circled-green"
          alt="Checkmark"
          height={19}
        />{" "}
        <strong className="is-line-height-small has-text-green is-size-3">
          Complete
        </strong>
      </>
    );
  }

  return (
    <div id={`step-${number}`}>
      <h2
        className={clsx(
          "stepper-header is-marginless",
          isGrayed && "has-background-tint-gray"
        )}
      >
        <button
          className={`stepper-button button is-ghost is-fullwidth is-size-2 has-text-left is-line-height-small ${
            subtitle ? "is-align-items-flex-start" : "is-align-items-center"
          }`}
          onClick={() => onToggle(isOpen ? -1 : number)}
        >
          <strong
            className={`stepper-circle is-flex is-align-items-center is-justify-content-center ${
              isUnlocked ? "has-background-blue" : "has-background-mid-gray"
            } has-text-white margin-right-2`}
          >
            {number}
          </strong>
          <span
            className={clsx(
              "is-flex-1 is-flex-column",
              isUnlocked ? "has-text-black" : "has-text-mid-gray"
            )}
          >
            <span className={clsx(isUnlocked && "has-text-blue")}>{title}</span>
            {subtitle && (
              <span className="stepper-subtitle is-flex is-align-items-flex-end has-text-weight-normal is-size-3">
                {subtitle}
              </span>
            )}
          </span>
          <SVG
            className={clsx("stepper-chevron", subtitle && "margin-top-1")}
            path={`/site/icon/${
              isOpen
                ? isUnlocked
                  ? "caret-up-heavy"
                  : "caret-up-heavy-gray"
                : isUnlocked
                ? "caret-down-heavy"
                : "caret-down-heavy-gray"
            }`}
            alt={`Caret ${isOpen ? "Up" : "Down"}`}
            width={24}
          />
        </button>
      </h2>

      <div
        className={clsx(
          "stepper-tab is-relative",
          !isLast && "has-vertical-line",
          subtitle && "is-line-extended",
          isNextOpen && "is-line-shortened",
          isOpen && "is-open has-background-tint-gray",
          !isUnlocked && "has-text-mid-gray"
        )}
      >
        {isOpen && (
          <div className="padding-x-2 padding-bottom-2">{children}</div>
        )}
      </div>
    </div>
  );
};

// Step that is highlighted (grayed) and should be completed next.
const todoStepNumber = (booking: BookingDO, tourYear: TourYearDO) => {
  if (isMissingGuestNames(booking)) {
    return 1;
  }
  if (hasDepositsDue(booking)) {
    return 2;
  }
  if (isMissingGuestDetails(booking, tourYear)) {
    return 3;
  }
  if (isMissingArrivalDeparture(booking)) {
    return 4;
  }
  if (hasFinalOrAdjDue(booking)) {
    return 5;
  }
  return -1; // complete
};
