import clsx from "clsx";
import { FC } from "react";

interface Props {
  className?: string;
  isFeeAvailable?: boolean;
  isFeeAdded: boolean;
  isFeePaid: boolean;
  isFeeWaived: boolean;
  total: number;
}

const PaymentStatus: FC<Props> = ({
  className,
  isFeeAvailable = true,
  isFeeAdded,
  isFeePaid,
  isFeeWaived,
  total,
}) => (
  <div
    className={clsx(
      "payment-status is-flex is-align-items-center is-justify-content-flex-end",
      className
    )}
  >
    {isFeePaid || isFeeWaived ? (
      <em className="is-line-height-small has-text-gray">Paid</em>
    ) : !isFeeAvailable ? (
      <span className="is-line-height-small has-text-gray margin-right-2">
        --
      </span>
    ) : isFeeAdded ? (
      <strong className="is-line-height-small">{total / 100}</strong>
    ) : null}
  </div>
);

export { PaymentStatus };
