import { ExistingBooking, ExistingBookingProps } from "../utils";
import { Container } from "../elements";
import { BookingCardContent, MinimalNavigation } from "../components";
import { db } from "../jsongo";
import { isHoldOrBookingActive, yearFromDate, TourYearDO } from "data-model";
import {
  useModal,
  ModalValue,
  SVG,
  CancelSelector,
  tableOfCancellationFees,
} from "react-components";
import { FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const context = "cancel-options";

const CancelOptions: FC = () => <ExistingBooking component={CancelBooking} />;

const CancelBooking: FC<ExistingBookingProps> = ({ booking, account }) => {
  const navigate = useNavigate();
  const setModal = useModal();

  const { guests, number, tour, departedAt } = booking;

  // false => don't cancel, true => cancel, arr idx => guest posIdx
  const [guestsToCancel, setGuestsToCancel] = useState(guests.map(() => false));

  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour,
    year: yearFromDate(departedAt),
  }) as TourYearDO;

  if (!isHoldOrBookingActive(booking, tourYear.timezoneEnd)) {
    return <Navigate to={`/bookings/${booking.number}`} />;
  }

  return (
    <Container>
      <h1 className="is-marginless" data-cy={`${context}-heading`}>
        Cancel Options
      </h1>
      <hr className="divider is-red margin-bottom-2" />

      <div className="has-background-light-gray has-border-gray is-rounded-small">
        <article className="padding-2 has-border-bottom-gray">
          <BookingCardContent
            booking={booking}
            hideInvite
            hideGuests
            hideStatus
            hideChanges
          />
        </article>

        <div className="padding-2 is-flex">
          <h2 className="is-marginless has-text-weight-normal is-size-2 margin-top-1">
            Cancel:
          </h2>
          <div className="is-flex-1 margin-left-3">
            <CancelSelector
              guests={booking.guests}
              guestsToCancel={guestsToCancel}
              labelClassName="is-size-2"
              onChange={setGuestsToCancel}
              owner={account}
              roomGuests={booking.roomGuests}
            />
          </div>
        </div>

        <footer className="padding-2 is-flex has-border-top-gray">
          <button
            className="button is-dark-gray margin-right-1"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="button is-yellow is-flex-1"
            disabled={guestsToCancel.every((c) => !c)}
            onClick={() =>
              navigate(
                `/bookings/${number}/cancel/preview?guests=${guestsToCancel.map(
                  Number
                )}`
              )
            }
          >
            Preview
          </button>
        </footer>
      </div>

      <AnemicNavigation onModal={setModal} />
    </Container>
  );
};

export { CancelOptions };

interface AnemicNavigationProps {
  onModal: (modalProps: ModalValue) => void;
}

const AnemicNavigation: FC<AnemicNavigationProps> = ({ onModal }) => (
  <MinimalNavigation>
    <li className="margin-y-3">
      <button
        className="button is-ghost is-link is-paddingless is-align-items-flex-start"
        onClick={() => onModal(cancellationFeesModal)}
      >
        <strong>Cancellation Fees</strong>
        <SVG
          className="margin-left-1"
          path="site/icon/question-circled-blue-solid"
          alt="Question Mark"
          height={15}
        />
      </button>
    </li>
  </MinimalNavigation>
);

export { AnemicNavigation };

const cancellationFeesModal: ModalValue = {
  isOpen: true,
  body: tableOfCancellationFees,
};
