import { FC, ReactNode } from "react";

interface Props {
  body: ReactNode;
  onClose: () => void;
  title: string;
}

const Notification: FC<Props> = ({ body, onClose, title }) => (
  <>
    <h1 id="modal-title" className="is-size-2">
      {title}
    </h1>
    {typeof body === "string" ? <p>{body}</p> : body}
    <button className="button is-fullwidth is-yellow" onClick={onClose}>
      OK
    </button>
  </>
);

export { Notification };
