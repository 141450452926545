import { CommonProps, Box, BoxHeading } from "./shared";
import { editGuestNames } from "../../../api";
import { AccountContext, ACCOUNT_KEY } from "../../../context";
import { ClientService } from "../../../components";
import {
  AccountDO,
  fullName,
  roomTypeCapitalized,
  isLastItem,
  arrOfSize,
  GuestDO,
  accountOwnerPosIdx,
  isMissingGuestNames,
  startingPosIdx,
  trim,
} from "data-model";
import { Input, SVG } from "react-components";
import { FC, ChangeEvent, FormEvent, useState, useContext } from "react";
import { set } from "idb-keyval";
import clsx from "clsx";

// const dataCy = "stepper-names-and-rooms";

const NamesAndRooms: FC<CommonProps> = ({ booking, account, onClose }) => {
  const { guests, roomGuests, id: bookingId } = booking;
  const isComplete = !isMissingGuestNames(booking);

  const [, setAccount] = useContext(AccountContext);
  const [guestNames, setGuestNames] = useState<string[]>(
    initGuestNames(guests, account)
  );

  const accountFullName = fullName(account);
  const ownerPosIdx = guestNames.indexOf(accountFullName); // first match

  let guestNumber = 0;

  const trimmedNames = trim(guestNames);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      dataset: { positionalIdx },
      value,
    } = e.currentTarget;

    setGuestNames(
      guestNames.map((name, i) => (i === Number(positionalIdx) ? value : name))
    );
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedAt = await editGuestNames(bookingId, trimmedNames);

    const updatedAccount = {
      ...account,
      bookings: account.bookings.map((b) => {
        if (b.id === bookingId) {
          return {
            ...b,
            guests: b.guests.map((g, posIdx) => ({
              ...g,
              fullName: trimmedNames[posIdx],
              updatedAt: updatedAt[posIdx],
            })),
          };
        }
        return b;
      }),
    };

    await set(ACCOUNT_KEY, updatedAccount);

    setAccount(updatedAccount);

    onClose();
  };

  return (
    <>
      {!isComplete && (
        <p className="margin-top-0 margin-bottom-2">
          <em>Please use legal names matching ID/Passport.</em>
        </p>
      )}

      <form onSubmit={handleSave}>
        {roomGuests.map((guestsInRoom, roomIndex) => (
          <Box key={roomIndex} className="margin-bottom-2">
            <BoxHeading
              className="margin-bottom-2"
              showCheckmark={isRoomComplete(
                trimmedNames,
                roomGuests,
                roomIndex
              )}
            >
              Room {roomIndex + 1} &ndash; {roomTypeCapitalized(guestsInRoom)}
            </BoxHeading>

            {arrOfSize(guestsInRoom).map((_, guestIndex, arr) => {
              guestNumber++;
              const positionalIdx = guestNumber - 1;

              return (
                <Input
                  key={positionalIdx}
                  id={`${positionalIdx}-full-name`}
                  label={`Guest ${guestNumber} First and Last Name`}
                  parentClassName={clsx(
                    !isLastItem(guestIndex, arr.length) && "margin-bottom-1"
                  )}
                  data-positional-idx={positionalIdx}
                  value={guestNames[positionalIdx] || ""}
                  onChange={handleNameChange}
                  disabled={isComplete}
                  childrenRight={
                    positionalIdx === ownerPosIdx && (
                      <SVG
                        className="field-helper"
                        path={
                          isComplete
                            ? "/site/icon/person-gray"
                            : "/site/icon/person-black"
                        }
                        alt="Person"
                        height={18}
                      />
                    )
                  }
                />
              );
            })}
          </Box>
        ))}

        {isComplete && (
          <p className="margin-top-2 margin-bottom-3">
            If you wish to change guest names, please call Caravan&apos;s{" "}
            <ClientService>Client Service</ClientService>.
          </p>
        )}

        <button className="button is-yellow is-fullwidth" disabled={isComplete}>
          Save and Continue
        </button>
      </form>
    </>
  );
};

export { NamesAndRooms };

const initGuestNames = (guests: GuestDO[], account: AccountDO) =>
  guests.map((guest, posIdx) => {
    // Prepopulate the first guest with account owner's full name
    if (
      posIdx === 0 &&
      guest.fullName === undefined && // only if it hasn't been filled out yet
      accountOwnerPosIdx(guests, account) === -1 // and the owner isn't at another arr index
    ) {
      return fullName(account);
    }
    return guest.fullName || "";
  });

const isRoomComplete = (
  guestNames: string[],
  roomGuests: number[],
  roomIdx: number
) => {
  const startPosIdx = startingPosIdx(roomGuests, roomIdx);
  const guestsInRoom = roomGuests[roomIdx];

  return arrOfSize(guestsInRoom).every(
    (_, guestIdx) => !!guestNames[startPosIdx + guestIdx]
  );
};
