import { sendCode, signUp } from "../api";
import { SmsChallenge, TravelAgentNotice } from "../components";
import { AuxContainer, Container } from "../elements";
import { AccountContext, ACCOUNT_KEY } from "../context";
import { parseHoldSpaceParams, parseTourNoticeParams } from "../utils";
import { Password } from "../form";
import {
  ErrorMessage,
  ExternalLink,
  PhoneNumber,
  Input,
  Select,
  SVG,
  hasError,
  useErrors,
  useModal,
} from "react-components";
import {
  BookingSignUpReq,
  countriesByCode,
  regionsByCountryCode,
  sortRegionsAlpha,
  trim,
} from "data-model";
import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { set } from "idb-keyval";

const SignUp: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setModal = useModal();
  const [, setAccount] = useContext(AccountContext);
  const [errors, catchErrors] = useErrors();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [country, setCountry] = useState("US");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [loading, setLoading] = useState(false);

  const phoneNumberId = "phone-number";

  const { search } = location;
  const params = new URLSearchParams(search);
  const holdSpaceReq = parseHoldSpaceParams(params);
  const tourNoticeReq = parseTourNoticeParams(params);

  useEffect(() => {
    window.pintrk("track", "pagevisit");
  }, []);

  const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const form: BookingSignUpReq = trim({
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      address: {
        line1,
        line2,
        city,
        state,
        country,
        zip,
      },
      onlyValidate: true,
    });
    form.password = password;

    await catchErrors(
      async () => {
        await signUp(form);
        await sendCode({ phoneNumber });
      },
      () => setLoading(false)
    );

    setModal({
      isOpen: true,
      body: (
        <SmsChallenge
          phoneNumber={phoneNumber}
          onReenter={() => {
            handleModalClose();
            setTimeout(() => document.getElementById(phoneNumberId)?.focus());
          }}
          onVerify={async (smsCode) => {
            form.smsCode = smsCode;
            delete form.onlyValidate;
            const account = await signUp(form);

            await set(ACCOUNT_KEY, account);

            window.pintrk("track", "signup");

            handleModalClose();

            if (tourNoticeReq) {
              window.location.href = `${process.env.WWW_ORIGIN}/tour/${tourNoticeReq.tour}?n=${tourNoticeReq.year}`;
            } else {
              setAccount(account);

              navigate(holdSpaceReq ? { pathname: "/", search } : "/bookings", {
                replace: true,
              });
            }
          }}
        />
      ),
      className: "is-fullsize-desktop",
    });
  };

  const handleModalClose = () =>
    setModal({ isOpen: false, body: null, className: "" });

  return (
    <form className="is-flex-column is-flex-1" onSubmit={handleSignUp}>
      <Container>
        <h1 className="is-marginless is-flex">
          <SVG
            className="margin-right-2"
            path="site/icon/person-black"
            alt="Person"
            height={20}
          />
          <span className="is-flex-1">Sign Up</span>
          <TravelAgentNotice />
        </h1>
        <hr className="divider is-red" />

        {(holdSpaceReq || tourNoticeReq) && (
          <p className="margin-y-2 has-text-red">
            <strong>
              Please Log In or Sign Up{" "}
              {holdSpaceReq ? "to hold space" : "for Email Notice"}
            </strong>
          </p>
        )}

        <p className="margin-y-2">
          Already have an account?{" "}
          <Link
            className="is-undecorated-link"
            to={{
              pathname: "/login",
              search,
            }}
          >
            <strong>Log in</strong>
          </Link>
        </p>

        <p className="margin-y-2">
          <em>Please use legal names matching ID/Passport.</em>
        </p>

        <div className="columns">
          <div className="column is-6">
            <Input
              id="first-name"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </div>
          <div className="column is-6">
            <Input
              id="last-name"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </div>
        </div>
        <PhoneNumber
          id={phoneNumberId}
          value={phoneNumber}
          onChange={setPhoneNumber}
          containerClass="margin-y-1"
          invalid={hasError(errors, "phoneNumber")}
          specialLabel="Mobile Phone"
        />
        <Input
          id="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "email")}
        />
        <Password
          id="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          parentClassName="margin-y-1"
          invalid={hasError(errors, "password")}
          minLength={4}
          autoComplete="new-password"
        />
        <Input
          id="line-1"
          label="Address Line 1"
          parentClassName="margin-y-1"
          value={line1}
          onChange={(e) => setLine1(e.currentTarget.value)}
        />
        <Input
          id="line-2"
          label="Address Line 2 (Optional)"
          parentClassName="margin-y-1"
          value={line2}
          onChange={(e) => setLine2(e.currentTarget.value)}
        />
        <Select
          id="country"
          label="Country"
          parentClassName="margin-y-1"
          value={country}
          onChange={(e) => {
            setCountry(e.currentTarget.value);
            setState("");
          }}
        >
          <option disabled hidden value="" />
          {Object.entries(countriesByCode).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          id="state"
          label="State, Province or Region"
          parentClassName="margin-y-1"
          value={state}
          onChange={(e) => setState(e.currentTarget.value)}
        >
          <option disabled hidden value="" />
          {country &&
            Object.entries(regionsByCountryCode[country])
              .sort(sortRegionsAlpha)
              .map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
        </Select>
        <div className="columns">
          <div className="column is-8">
            <Input
              id="city"
              label="City"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </div>
          <div className="column is-4">
            <Input
              id="zip"
              label="Zip / Postal"
              value={zip}
              onChange={(e) => setZip(e.currentTarget.value)}
              minLength={1}
              maxLength={10}
            />
          </div>
        </div>

        <ErrorMessage
          errors={errors}
          className="margin-top-2 margin-bottom-0"
        />

        <ul className="margin-top-4 margin-bottom-0 padding-left-6">
          <li>
            <strong>Documents and Receipts</strong> sent via email.
          </li>
          <li>
            <strong>Notifications</strong> sent via SMS to your phone.
          </li>
          <li>
            By creating an account you agree to Caravan&apos;s{" "}
            <ExternalLink
              className="is-undecorated-link"
              target="_blank"
              href={`${process.env.WWW_ORIGIN}/caravan-terms-of-travel.pdf`}
            >
              <strong>Terms of Travel</strong>
            </ExternalLink>
            ,{" "}
            <ExternalLink
              className="is-undecorated-link"
              target="_blank"
              href={`${process.env.WWW_ORIGIN}/terms-of-service#site-usage-agreement`}
            >
              <strong>Site Usage Agreement</strong>
            </ExternalLink>
            ,{" "}
            <ExternalLink
              className="is-undecorated-link"
              target="_blank"
              href={`${process.env.WWW_ORIGIN}/privacy-policy`}
            >
              <strong>Privacy Policy</strong>
            </ExternalLink>
            , and that you are at least 18 years of age.
          </li>
        </ul>
      </Container>
      <AuxContainer>
        <button
          type="submit"
          className="button is-fullwidth is-yellow"
          disabled={
            loading ||
            !firstName ||
            !lastName ||
            !phoneNumber ||
            !email ||
            !password ||
            !line1 ||
            !country ||
            !state ||
            !city ||
            !zip
          }
        >
          Sign Up{holdSpaceReq ? " and Hold Space" : ""}
        </button>
      </AuxContainer>
    </form>
  );
};

export { SignUp };
