import { AccountContext } from "../context";
import { logOut } from "../api";
import { Container } from "../elements";
import { isLastItem } from "data-model";
import { SVG } from "react-components";
import { FC, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { clear } from "idb-keyval";
import clsx from "clsx";

const context = "my-account";

const MyAccount = () => {
  const navigate = useNavigate();

  const [, setAccount] = useContext(AccountContext);

  const handleLogout = async () => {
    await logOut();

    await clear();

    setAccount(undefined);

    navigate("/login");
  };

  return (
    <Container>
      <h1
        className="is-marginless is-flex is-align-items-center"
        data-cy={`${context}-heading`}
      >
        <span className="is-flex-1">My Account</span>
        <Link to="/bookings" className="is-size-3 is-flex">
          Back
          <SVG
            className="margin-left-1"
            path="site/icon/person-light-blue-outlined"
            alt="Person Outlined"
            height={15}
          />
        </Link>
      </h1>
      <hr className="divider is-red" />

      <h2 className="margin-top-3 margin-bottom-2">Bookings</h2>

      <Tabs
        links={[
          ["Active Bookings", "/bookings"],
          ["Cancelled Bookings", "/bookings/cancelled"],
          ["Travelled Bookings", "/bookings/travelled"],
          ["Expired & Deleted Holds", "/bookings/expired"],
        ]}
      />

      {/* <h2 className="margin-top-4 margin-bottom-2">Payments</h2>

      <Tabs
        links={[
          ["Your Payments", "/payments"],
          ["Your Credits", "/credits"],
        ]}
      /> */}

      <h2 className="margin-top-4 margin-bottom-2">Account Settings</h2>

      <Tabs
        links={[["Edit Account Information", "/account/edit"]]}
        buttons={[["Log Out", handleLogout]]}
      />
    </Container>
  );
};

interface TabsProps {
  links: string[][];
  buttons?: [string, () => void][];
}

const caretRight = (
  <SVG
    path="site/icon/caret-right-heavy-blue"
    alt="Caret Right Blue"
    height={19}
  />
);

const Tabs: FC<TabsProps> = ({ links, buttons = [] }) => (
  <div className="has-border-gray is-rounded-small">
    {links.map(([label, href], idx) => (
      <Link
        key={idx}
        to={href}
        className={clsx(
          "padding-y-2 padding-left-4 padding-right-2 has-text-blue is-flex",
          (!isLastItem(idx, links.length) || buttons.length > 0) &&
            "has-border-bottom-gray"
        )}
      >
        <span className="is-flex-1">{label}</span>
        {caretRight}
      </Link>
    ))}
    {buttons.map(([label, onClick], idx) => (
      <button
        key={idx}
        className="button is-ghost is-link is-fullwidth padding-left-4 has-text-weight-normal has-text-left has-text-blue"
        onClick={onClick}
      >
        <span className="is-flex-1">{label}</span>
        {caretRight}
      </button>
    ))}
  </div>
);

export { MyAccount };
