import { useModal } from "react-components";
import { FC } from "react";

const TravelAgentNotice = () => {
  const setModal = useModal();

  const handleClose = () =>
    setModal({
      isOpen: false,
      className: "",
      body: null,
    });

  return (
    <button
      type="button"
      className="button is-ghost is-link is-paddingless"
      onClick={() =>
        setModal({
          isOpen: true,
          className: "is-fullwidth",
          body: <TravelAgentBody onClose={handleClose} />,
        })
      }
    >
      Travel Agent?
    </button>
  );
};

export { TravelAgentNotice };

interface TravelAgentBodyProps {
  onClose: () => void;
}

const TravelAgentBody: FC<TravelAgentBodyProps> = ({ onClose }) => (
  <>
    <h1>Special Notice to Travel Agents</h1>

    <p>
      Over the years, Caravan has enjoyed a long and successful relationship
      with many knowledgeable and dedicated Travel Agents in the US and around
      the world. Beginning with all tours operating in 2023 Caravan will no
      longer offer commission to Travel Agents. We thank you for your hard work
      and collaboration and we wish you good luck in the future.
    </p>

    <p>
      All tour reservations will be handled directly between Caravan and our
      travelers.
    </p>

    <p>
      Sincerely,
      <br />
      Caravan
    </p>

    <button
      className="button is-light-blue is-outlined is-fullwidth"
      onClick={onClose}
    >
      Close
    </button>
  </>
);
