import { extendHold } from "../api";
import { ACCOUNT_KEY, AccountContext } from "../context";
import { BookingDO, canExtendHoldBy } from "data-model";
import { SVG, useErrors } from "react-components";
import { FC, useContext, useState } from "react";
import { set } from "idb-keyval";

interface ExtendHoldProps {
  booking: BookingDO;
}

const ExtendHold: FC<ExtendHoldProps> = ({ booking }) => {
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const [account, setAccount] = useContext(AccountContext);
  if (!account) throw new Error("unreachable");

  const hours = canExtendHoldBy(booking);
  if (!hours) return null;

  const handleExtendHold = async () => {
    setIsLoading(true);

    await catchErrors(
      async () => {
        const { expiredAt } = await extendHold(booking.id);

        const updatedAccount = {
          ...account,
          bookings: account.bookings.map((b) => {
            if (b.id === booking.id) {
              return {
                ...b,
                expiredAt,
                depositDueBy: expiredAt,
                finalDueBy: expiredAt > b.finalDueBy ? expiredAt : b.finalDueBy,
              };
            }
            return b;
          }),
        };

        await set(ACCOUNT_KEY, updatedAccount);

        setAccount(updatedAccount);
      },
      () => setIsLoading(false)
    );
  };

  return isLoading ? (
    <SVG
      path="/site/icon/spinner"
      className="is-inline-flex is-vertical-align-middle margin-left-2"
      alt="Spinner"
      height={17}
    />
  ) : (
    <button
      className="button is-ghost is-link is-paddingless margin-left-2 is-inline extend-hold"
      onClick={handleExtendHold}
    >
      Extend {hours} <span>Hours</span> <span>Hrs</span>
    </button>
  );
};

export { ExtendHold };
