import { CommonProps, Box, BoxHeading } from "./shared";
import {
  PaidFee,
  UnpaidFee,
  UnavailableFee,
  PaymentStatus,
  Checkout,
  WaivedFee,
} from "../PaymentEditor";
import { getFormToken, savePayment } from "../../../api";
import { AccountContext, ACCOUNT_KEY } from "../../../context";
import {
  AccountDO,
  arrDelete,
  canBuyTpp,
  depositFee,
  FeeDO,
  FeeDO_Type,
  feePayment,
  formatCents,
  fullNameOrPlaceholder,
  GuestDO,
  initFeesByGuest,
  isFeeOwed,
  isFeePaid,
  isFeeWaived,
  isGuestAccountOwner,
  isLastItem,
  mapPaymentResponse,
  PaymentDO,
  totalToPayNow,
  tppFee,
} from "data-model";
import {
  Checkbox,
  ExternalLinkOrText,
  PaymentForm,
  SVG,
  useModal,
} from "react-components";
import { FC, Fragment, useContext, useState } from "react";
import { klona } from "klona/json";
import clsx from "clsx";
import { set } from "idb-keyval";

// const dataCy = "stepper-deposit-payment";

const DepositPayment: FC<CommonProps> = ({
  booking,
  account,
  onClose,
  isUnlocked,
}) => {
  const { guests, id: bookingId, departedAt } = booking;
  const isLocked = !isUnlocked;

  const setModal = useModal();
  const [, setAccount] = useContext(AccountContext);
  const [fees, setFees] = useState(initFeesByGuest(guests));

  const feesDue = guests.map((g) =>
    unpaidDepositAndTppIfAvailable(g, departedAt, isLocked)
  );
  const hasUnpaidFees = feesDue.flat().length > 0;
  const isPayAll = fees.every(
    (guestFees, posIdx) => guestFees.length === feesDue[posIdx].length
  );

  const total = hasUnpaidFees
    ? totalToPayNow(fees, guests)
    : depositAndTppTotal(guests);
  const totalFormatted = formatCents(total);

  const handlePayAll = () =>
    setFees(
      fees.map((_, posIdx) =>
        isPayAll ? [] : feesDue[posIdx].map((fee) => fee.type)
      )
    );

  const handleFeeChange = (
    positionalIdx: number,
    feeType: FeeDO_Type,
    isAdded: boolean
  ) => {
    const newFees = klona(fees);
    const feesForThisGuest = newFees[positionalIdx];

    if (isAdded) {
      feesForThisGuest.push(feeType);

      // When TPP is added, auto-add deposit (unless already purchased)
      if (
        feeType === FeeDO_Type.TPP &&
        !feesForThisGuest.includes(FeeDO_Type.DEPOSIT) &&
        isFeeOwed(depositFee(guests[positionalIdx]))
      ) {
        feesForThisGuest.push(FeeDO_Type.DEPOSIT);
      }
    } else {
      arrDelete(feesForThisGuest, feeType);

      // When deposit is removed, auto-remove TPP (if present)
      if (feeType === FeeDO_Type.DEPOSIT) {
        arrDelete(feesForThisGuest, FeeDO_Type.TPP);
      }
    }

    setFees(newFees);
  };

  const handleCheckout = async () => {
    const { token } = await getFormToken(bookingId, fees);

    setModal({
      isOpen: true,
      className: "is-fullwidth",
      body: (
        <PaymentForm
          token={token}
          onClose={() => setModal({ isOpen: false, className: "", body: null })}
          onSuccess={async (res) => {
            const paymentReq = mapPaymentResponse(res);

            const payment = await savePayment(bookingId, {
              ...paymentReq,
              fees,
            });

            const updatedAccount = addPaymentTo(
              account,
              bookingId,
              payment,
              fees
            );

            await set(ACCOUNT_KEY, updatedAccount);

            setAccount(updatedAccount);

            onClose();
          }}
        />
      ),
    });
  };

  return (
    <Box>
      <div className="is-flex is-align-items-center margin-bottom-2">
        <h3 className="is-flex-1 is-marginless is-size-2 has-text-weight-normal">
          Check Items to Pay Now: {!hasUnpaidFees && <strong>Done</strong>}
        </h3>
        {hasUnpaidFees && (
          <Checkbox
            id="pay-all"
            labelClassName="has-text-weight-bold is-size-2 margin-left-1"
            checked={isPayAll}
            onChange={handlePayAll}
            disabled={isLocked}
          >
            Pay All
          </Checkbox>
        )}
      </div>

      <BoxHeading
        className="margin-bottom-1"
        showPerson={isGuestAccountOwner(guests, account, 0)}
        isUnlocked={isUnlocked}
      >
        {fullNameOrPlaceholder(guests, account, 0)}
      </BoxHeading>

      <div className="is-flex">
        <div className="is-flex-1">
          {guests.map((guest, posIdx) => {
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            const depositPayment = feePayment(deposit);
            const tppPayment = feePayment(tpp);
            const isLast = isLastItem(posIdx, guests.length);

            return (
              <Fragment key={guest.id}>
                {posIdx !== 0 && (
                  <BoxHeading
                    className="margin-y-1"
                    showPerson={isGuestAccountOwner(guests, account, posIdx)}
                    isUnlocked={isUnlocked}
                  >
                    {fullNameOrPlaceholder(guests, account, posIdx)}
                  </BoxHeading>
                )}

                {depositPayment ? (
                  <PaidFee
                    className="margin-bottom-1"
                    title="Deposit"
                    total={deposit.total}
                    payment={depositPayment}
                  />
                ) : isFeeWaived(deposit) ? (
                  <WaivedFee className="margin-bottom-1" title="Deposit" />
                ) : (
                  <UnpaidFee
                    className="margin-bottom-1"
                    title="Deposit"
                    total={deposit.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.DEPOSIT)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.DEPOSIT}
                    isLocked={isLocked}
                  />
                )}

                {tppPayment ? (
                  <PaidFee
                    className={clsx(!isLast && "margin-bottom-1")}
                    title="Protection"
                    total={tpp.total}
                    payment={tppPayment}
                  />
                ) : isFeeWaived(tpp) ? (
                  <WaivedFee
                    className={clsx(!isLast && "margin-bottom-1")}
                    title="Travel Protection"
                  />
                ) : canBuyTpp(guest, departedAt, false, isLocked) ? (
                  <UnpaidFee
                    className={clsx(!isLast && "margin-bottom-1")}
                    title={
                      <span className="is-flex">
                        Travel Protection
                        <ExternalLinkOrText
                          isLink={isUnlocked}
                          href={`${process.env.WWW_ORIGIN}/caravan-travel-protection.pdf`}
                        >
                          <SVG
                            className="margin-left-1"
                            path={
                              isUnlocked
                                ? "/site/icon/question-circled-blue"
                                : "/site/icon/question-circled-gray"
                            }
                            alt="Info"
                            height={16}
                          />
                        </ExternalLinkOrText>
                      </span>
                    }
                    total={tpp.total}
                    isAdded={fees[posIdx].includes(FeeDO_Type.TPP)}
                    onChange={handleFeeChange}
                    positionalIdx={posIdx}
                    feeType={FeeDO_Type.TPP}
                    isLocked={isLocked}
                  />
                ) : (
                  <UnavailableFee
                    className={clsx(!isLast && "margin-bottom-1")}
                    title="Protection"
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <aside className="payment-column margin-left-1 has-border-gray is-rounded-small padding-left-1 padding-right-2 is-flex-column is-justify-content-space-between">
          {guests.map((guest, posIdx) => {
            const isDepositAdded = fees[posIdx].includes(FeeDO_Type.DEPOSIT);
            const isTppAdded = fees[posIdx].includes(FeeDO_Type.TPP);
            const deposit = depositFee(guest);
            const tpp = tppFee(guest);
            return (
              <div key={posIdx} className="is-size-2">
                <PaymentStatus
                  className="margin-bottom-1"
                  isFeeAdded={isDepositAdded}
                  isFeePaid={isFeePaid(deposit)}
                  isFeeWaived={isFeeWaived(deposit)}
                  total={deposit.total}
                />
                <PaymentStatus
                  isFeeAdded={isTppAdded}
                  isFeeAvailable={canBuyTpp(guest, departedAt, false, isLocked)}
                  isFeePaid={isFeePaid(tpp)}
                  isFeeWaived={isFeeWaived(tpp)}
                  total={tpp.total}
                />
              </div>
            );
          })}
        </aside>
      </div>

      <Checkout
        hasUnpaidFees={hasUnpaidFees}
        isLocked={isLocked}
        onCheckout={handleCheckout}
        total={total}
        totalFormatted={totalFormatted}
      />
    </Box>
  );
};

export { DepositPayment };

export const addPaymentTo = (
  oldAccount: AccountDO,
  bookingId: number,
  payment: PaymentDO,
  feesPaid: FeeDO_Type[][]
): AccountDO => ({
  ...oldAccount,
  bookings: oldAccount.bookings.map((b) => {
    if (b.id === bookingId) {
      return {
        ...b,
        guests: b.guests.map((g, posIdx) => {
          return {
            ...g,
            fees: g.fees.map((f) => {
              if (feesPaid[posIdx].includes(f.type)) {
                return {
                  ...f,
                  paymentLineItems: [
                    ...f.paymentLineItems,
                    {
                      fee: f.id as unknown as FeeDO,
                      payment,
                      amount: f.amount,
                      tax: f.tax,
                      total: f.total,
                    },
                  ],
                };
              }
              return f;
            }),
          };
        }),
      };
    }
    return b;
  }),
});

const unpaidDepositAndTppIfAvailable = (
  guest: GuestDO,
  departedAt: string,
  isLocked: boolean
) => {
  const fees = [];

  const deposit = depositFee(guest);
  if (isFeeOwed(deposit)) fees.push(deposit);

  // We pass isLocked in case all deposits were paid in Danno but none of the names were provided.
  if (canBuyTpp(guest, departedAt, false, isLocked)) fees.push(tppFee(guest));

  return fees;
};

const depositAndTppTotal = (guests: GuestDO[]) =>
  guests.reduce((total, guest) => {
    const deposit = depositFee(guest);
    const tpp = tppFee(guest);
    if (isFeePaid(deposit)) total += deposit.total;
    if (isFeePaid(tpp)) total += tpp.total;
    return total;
  }, 0);
