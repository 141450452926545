const { default: airports } = await import(
  `../../../../${process.env.JSONGO_DIR}/airport.json`
);
const { default: global } = await import(
  `../../../../${process.env.JSONGO_DIR}/global.json`
);
const { default: hotels } = await import(
  `../../../../${process.env.JSONGO_DIR}/hotel.json`
);
// const { default: imgs } = await import(
//   `../../../../${process.env.JSONGO_DIR}/img.json`
// );
const { default: series } = await import(
  `../../../../${process.env.JSONGO_DIR}/series.json`
);
const { default: tours } = await import(
  `../../../../${process.env.JSONGO_DIR}/tour.json`
);
const { default: tourChanges } = await import(
  `../../../../${process.env.JSONGO_DIR}/tourChange.json`
);
const { default: tourYears } = await import(
  `../../../../${process.env.JSONGO_DIR}/tourYear.json`
);
const { default: travelDocuments } = await import(
  `../../../../${process.env.JSONGO_DIR}/travelDocument.json`
);

import {
  getGlobalDO,
  getPublicTourIds,
  publicTourDOs,
  keyBy,
  HotelDO_Index,
  TourDO,
  AirportDO_Index,
} from "data-model";
import { memDB } from "jsongo";

export const db = memDB();

// TODO filter
db.airport.insertMany(airports);
db.global.insertMany(global);
db.hotel.insertMany(hotels);
// db.img.insertMany(imgs);
db.series.insertMany(series);
db.tour.insertMany(tours);
db.tourChange.insertMany(tourChanges);
db.tourYear.insertMany(tourYears);
db.travelDocument.insertMany(travelDocuments);

export const globalDO = getGlobalDO(db);

export const publicTourIds = getPublicTourIds(db);

export const publicToursById = keyBy(publicTourDOs(db), "_id");

// Include historical tours that are no longer running.
export const toursById = keyBy(db.tour.docs() as TourDO[], "_id");

export const airportsById = db.airport.index("_id") as AirportDO_Index;
export const hotelsById = db.hotel.index("_id") as HotelDO_Index;
