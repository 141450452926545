import { db, toursById } from "../jsongo";
import {
  BookingDO,
  yearFromDate,
  totalGuestsFrom,
  pluralize,
  TourYearDO,
  TourChangeDO,
  ccc_MMM_d_yyyy,
} from "data-model";
import { Image, statusForHumans, SVG } from "react-components";
import { FC, Fragment, PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props extends CardContentProps {
  className?: string;
  footer?: ReactNode;
  header?: ReactNode;
  withImages?: boolean;
}

const BookingCard: FC<Props> = (props) => {
  const { className, footer, header, withImages = false, ...rest } = props;
  const tour = toursById[props.booking.tour];
  const headerA = tour.page["headerA(img_id)"];
  const headerB = tour.page["headerB(img_id)"];

  return (
    <>
      {withImages && (
        <div className="columns padding-bottom-1">
          <div className="column">
            <figure className="golden">
              <Image path={headerA} alt="Tour Thumbnail Left" />
            </figure>
          </div>
          <div className="column">
            <figure className="golden">
              <Image path={headerB} alt="Tour Thumbnail Right" />
            </figure>
          </div>
        </div>
      )}
      <div
        className={clsx(
          "padding-3 has-border-gray is-rounded-small is-line-height-medium",
          className
        )}
      >
        {header}

        <BookingCardContent {...rest} />

        {footer}
      </div>
    </>
  );
};

export { BookingCard };

interface CardContentProps {
  booking: BookingDO;
  hideNumber?: boolean;
  hideInvite?: boolean;
  hideTourEnd?: boolean;
  hideGuests?: boolean;
  hideParty?: boolean;
  hideStatus?: boolean;
  hideChanges?: boolean;
  status?: ReactNode;
  titleHeading?: "h1" | "h2" | "h3";
  numberIsAdjacent?: boolean;
}

const BookingCardContent: FC<CardContentProps> = ({
  booking,
  hideNumber = false,
  hideInvite = false,
  hideTourEnd = false,
  hideGuests = false,
  hideParty = false,
  hideStatus = false,
  hideChanges = false,
  status,
  titleHeading: Title = "h2",
  numberIsAdjacent = false,
}) => {
  const tour = toursById[booking.tour];

  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: tour._id,
    year: yearFromDate(booking.departedAt),
  }) as TourYearDO;
  const { cityStart, cityEnd } = tourYear;
  const totalGuests = totalGuestsFrom(booking.roomGuests);
  const tourChanges = db.tourChange
    .find({ tourYear_id: tourYear._id })
    .all() as TourChangeDO[];

  if (booking.cancelledAt) {
    hideGuests = true;
  }

  return (
    <>
      {!hideNumber && (
        <p
          className={`margin-top-0 ${
            numberIsAdjacent ? "margin-bottom-0" : "margin-bottom-1"
          } is-flex`}
        >
          <span className="is-flex-1">{booking.number}</span>
          {!hideInvite && (
            <Link to={`/bookings/${booking.number}/invite`}>
              <strong>Invite Friends</strong>
              <SVG
                className="is-inline-flex margin-left-1"
                path="site/icon/share-curved-arrow-heavy"
                alt="Blue curved arrow"
                height={15}
              />
            </Link>
          )}
        </p>
      )}

      <Title className="is-marginless is-size-1 is-line-height-medium">
        {tour.name}
      </Title>
      <p className="is-marginless">
        {!hideChanges &&
          tourChanges.map((change) => (
            <Fragment key={change._id}>
              <strong className="has-text-red">{change.briefNotice}</strong>
              <br />
            </Fragment>
          ))}
        <strong className="padding-right-1">Start</strong>{" "}
        {DateTime.fromISO(booking.departedAt).toFormat(ccc_MMM_d_yyyy)},{" "}
        {cityStart}
        <br />
        {!hideTourEnd && (
          <>
            <strong className="padding-right-1">End</strong>{" "}
            {DateTime.fromISO(booking.departure.concludedAt).toFormat(
              ccc_MMM_d_yyyy
            )}
            , {cityEnd}
            <br />
          </>
        )}
        {!hideGuests && (
          <>
            <strong className="padding-right-1">
              {totalGuests} Guest{pluralize(totalGuests)}
            </strong>{" "}
            {booking.roomGuests.length} Room
            {pluralize(booking.roomGuests.length)}
            <br />
          </>
        )}
        {!hideParty && booking.party && (
          <>
            <strong className="padding-right-1">Party</strong>{" "}
            {booking.party.name}
            <br />
          </>
        )}
        {!hideStatus && (
          <BookingStatus booking={booking} tourYear={tourYear}>
            {status}
          </BookingStatus>
        )}
      </p>
    </>
  );
};

export { BookingCardContent };

interface BookingStatusProps extends PropsWithChildren {
  booking: BookingDO;
  tourYear: TourYearDO;
}

const BookingStatus: FC<BookingStatusProps> = ({
  booking,
  children,
  tourYear,
}) => {
  const status = statusForHumans(booking, tourYear);

  return (
    <>
      {status.label && (
        <strong className="padding-right-1">{status.label} </strong>
      )}
      {status.description}
      {children}
    </>
  );
};

export { BookingStatus };
