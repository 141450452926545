import clsx from "clsx";
import { AccountDO, BookingDO } from "data-model";
import { SVG } from "react-components";
import { FC, PropsWithChildren } from "react";

export interface CommonProps {
  booking: BookingDO;
  account: AccountDO;
  onClose: VoidFunction;
  isUnlocked: boolean;
}

interface BoxProps extends PropsWithChildren {
  className?: string;
}

const Box: FC<BoxProps> = ({ className, children }) => (
  <div
    className={clsx(
      "has-background-white has-border-mid-gray is-rounded-small padding-2",
      className
    )}
  >
    {children}
  </div>
);

export { Box };

interface BoxHeadingProps extends PropsWithChildren {
  className?: string;
  heading?: "h3" | "h4";
  isUnlocked?: boolean;
  showCheckmark?: boolean;
  showPerson?: boolean;
}

const BoxHeading: FC<BoxHeadingProps> = ({
  children,
  className,
  heading: H = "h3",
  isUnlocked,
  showCheckmark,
  showPerson,
}) => (
  <H className={clsx("is-size-2 is-flex is-marginless", className)}>
    <span
      className={clsx(
        "is-flex-1",
        showPerson && "is-flex is-align-items-baseline"
      )}
    >
      {children}
      {showPerson && (
        <SVG
          className="margin-left-1"
          path={
            isUnlocked ? "/site/icon/person-black" : "/site/icon/person-gray"
          }
          alt="Person"
          height={18}
        />
      )}
    </span>
    {showCheckmark && (
      <SVG
        className="is-align-self-center"
        path="/site/icon/checkmark-circled-green"
        alt="Checkmark"
        height={20}
      />
    )}
  </H>
);

export { BoxHeading };
